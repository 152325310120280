import Api from '../api';

import {
  InviteUserParams,
  ListUsersParams,
  ListUsersResult,
  UpdateUserParams,
  User,
} from './types';

class UserService extends Api {
  async InviteUser(data: InviteUserParams) {
    return this.doRequest<User>({
      endpoint: '/users',
      data,
    });
  }

  async ListUsers(data: ListUsersParams) {
    return this.doRequest<ListUsersResult>({
      endpoint: '/users',
      data,
      method: 'GET',
    });
  }

  async GetUser(id: string) {
    return this.doRequest<User>({
      endpoint: `/users/${id}`,
    });
  }

  async DeleteUser(id: string) {
    return this.doRequest({
      endpoint: `/users/${id}`,
      method: 'DELETE',
    });
  }

  async UpdateUser(id: string, data: UpdateUserParams) {
    return this.doRequest<User>({
      endpoint: `/users/${id}`,
      data,
      method: 'PUT',
    });
  }
}

export const userService = new UserService();
