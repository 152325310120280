import React, { createContext, useCallback, useContext, useState } from 'react';

import ToastContainer from '../components/ToastContainer/ToastContainer';
import { uid } from '../utils/string';

import { ToastProps } from './types';

type AddToastParams = Omit<ToastProps, 'id' | 'index' | 'removeToast'>;

interface ToastContextData {
  addToast: (params: AddToastParams) => void;
  removeToast: (id: string) => void;
  onErrorToast: (error: Error) => void;
}

const ToastContext = createContext<ToastContextData>({} as ToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<ToastProps[]>([]);

  const removeToast = useCallback((id: string) => {
    setMessages((state) =>
      state
        .filter((message) => message.id !== id)
        .map((msg, index) => ({
          ...msg,
          index,
        }))
    );
  }, []);

  const addToast = useCallback(
    ({ type, message }) => {
      setMessages((state) => {
        const id = uid();
        const index = state.length;
        const toast = { id, index, type, message, removeToast };
        return [...state, toast].map((msg, index) => ({
          ...msg,
          index,
        }));
      });
    },
    [removeToast]
  );

  const onErrorToast = useCallback(
    (error: Error) => {
      addToast({ type: 'error', message: error.message });
    },
    [addToast]
  );

  return (
    <ToastContext.Provider value={{ addToast, removeToast, onErrorToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

export function useToast(): ToastContextData {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
}
