import React, { createContext, useCallback, useContext, useState } from 'react';

import { Dataset } from 'common-utils';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { datasetService } from '../services';
import { IResponse } from '../services/api';
import { Company } from '../services/companies/types';
import { DisclosureTopic } from '../services/datasets/types';
import { reportService } from '../services/reports';
import {
  CloneReportProviderParams,
  DeleteReportResponse,
  ListReportsFilter,
  Report,
  ReportResponse,
} from '../services/reports/types';
import { GetPlanResponse } from '../services/stripe/types';
import { Aws } from '../utils/aws';
import { defaultReportData } from '../utils/report';

import { useAuth } from './auth';
import { useSASB } from './sasb';
import { useStripe } from './stripe';
import { CreateReportParams, SaveReportParams } from './types';

export type { Report };

interface FetchReportParams {
  search?: string; // Send search string to the API.
  filter?: string;
  clear?: boolean; // Replace existing entries.
}

interface ReportContextData {
  reports: Report[];
  fetchReports: (params?: FetchReportParams) => Promise<void>;
  createReport: (data: CreateReportParams) => Promise<Report>;
  cloneReport: (
    reportId: string,
    data: CloneReportProviderParams
  ) => Promise<Report>;
  getReport: (id: string) => Promise<Report>;
  saveReport: (id: string, data: SaveReportParams) => Promise<Report>;
  uploadImage: (id: string, imageId: string, image: File) => Promise<void>;
  getImage: (id: string, imageId: string) => Promise<File>;
  draftReport: (id: string) => Promise<string>;
  approveReport: (id: string) => Promise<void>;
  archiveReport: (id: string) => Promise<Report>;
  unarchiveReport: (id: string) => Promise<Report>;
  deleteReport: (id: string) => Promise<DeleteReportResponse>;
  downloadReport: (id: string, name: string) => Promise<File | null>;
  clearState: () => void;
}

const ReportContext = createContext<ReportContextData>({} as ReportContextData);

export const ReportProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('reports');
  const { user } = useAuth();
  const { plan } = useStripe();
  const { listTopics } = useSASB();

  const [reports, setReports] = useState<Report[]>([]);
  const [filter, setFilter] = useState<string | undefined>();

  const commonCompanyParams = (company: Company, plan: GetPlanResponse) => {
    const formatAddress = (street?: string, city?: string): string => {
      let result = street ?? '';
      if (city?.length) {
        result = result.concat(result.length ? ', ' : '').concat(city);
      }
      return result;
    };

    return {
      name: company.name,
      address: formatAddress(
        company.addresses[0].street,
        company.addresses[0].city
      ),
      country: company.country,
      website: company.website,
      sizeCode: company.size?.code ?? '',
      industryCode: company.industry?.code ?? '',
      companyTypeCode: company.companyType?.code ?? '',
      currency: company.currency ?? '',
      plan: plan.plan.type,
    };
  };

  const fetchReports = useCallback(
    async (params?: FetchReportParams) => {
      setFilter(params?.filter);
      return new Promise<void>((resolve, reject) => {
        reportService
          .List({
            limit: 50,
            offset: params?.clear ? 0 : reports.length,
            search: params?.search === '' ? undefined : params?.search,
            filter: params?.filter as ListReportsFilter,
          })
          .then((result) => {
            if (result.error) reject(result.error);
            else {
              setReports((state) =>
                params?.clear ? result : _.unionBy(state, result, 'id')
              );
              resolve();
            }
          });
      });
    },
    [reports.length]
  );

  const createReport = useCallback(
    async (data: CreateReportParams) => {
      if (user?.company && plan) {
        const { company } = user;

        let dataset: Dataset;
        let topics: DisclosureTopic[];
        try {
          dataset = await datasetService.Get(data.dataset);
          topics = await listTopics(data.language);
        } catch (e) {
          console.error(e);
        }

        return new Promise<Report>((resolve, reject) => {
          reportService
            .CreateReport(
              defaultReportData(
                {
                  ...data,
                  status: 0,
                  company: commonCompanyParams(company, plan),
                },
                dataset?.answers ?? [],
                topics ?? [],
                t
              )
            )
            .then((result) => {
              if (result?.error) reject(result.error);
              else {
                setReports((state) => [result.data, ...state]);
                resolve(result.data);
              }
            });
        });
      }
      return Promise.reject(Error('User is undefined'));
    },
    [user, plan, listTopics, t]
  );

  const cloneReport = useCallback(
    async (reportId: string, data: CloneReportProviderParams) => {
      if (!user?.company || !plan) {
        return Promise.reject(Error('User is undefined'));
      }

      const { metadata } = data;
      return new Promise<Report>((resolve, reject) => {
        reportService
          .CloneReport(reportId, {
            ...data,
            metadata: {
              ...metadata,
              company: commonCompanyParams(user.company, plan),
            },
          })
          .then((result) => {
            if (result?.error) reject(result.error);
            else {
              setReports((state) => [result.data, ...state]);
              resolve(result.data);
            }
          });
      });
    },
    [user, plan]
  );

  const getReport = useCallback((id: string) => {
    return new Promise<Report>((resolve, reject) => {
      reportService.GetReport(id).then((result) => {
        if (result?.error) reject(result.error);
        else resolve(result);
      });
    });
  }, []);

  const saveReport = useCallback((id, data) => {
    data.metadata.parentId = data.metadata.revisionId;
    return new Promise<Report>((resolve, reject) => {
      reportService.SaveReport(id, data).then((result) => {
        if (result?.error) reject(result.error);
        else {
          const { data } = result;
          setReports((state) =>
            state.map((report) => {
              if (report.id === data.id) return data;
              return report;
            })
          );
          resolve(data);
        }
      });
    });
  }, []);

  const uploadImage = useCallback(
    async (id: string, imageId: string, image: File) => {
      const response = await reportService.PutImage(id, imageId, image);
      if (response.error) {
        throw response.error;
      }
    },
    []
  );

  const getImage = useCallback(async (id: string, imageId: string) => {
    const url = await reportService.GetImage(id, imageId);
    if (url.error) {
      throw url.error;
    }

    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], imageId);
  }, []);

  const draftReport = useCallback((id) => {
    return new Promise<string>((resolve, reject) => {
      reportService.DraftReport(id).then((result) => {
        if (result.error) reject(result.error);
        else resolve(result);
      });
    });
  }, []);

  const approveReport = useCallback(
    (id) => {
      return new Promise<void>((resolve, reject) => {
        reportService.ApproveReport(id).then((result) => {
          if (result?.error) reject(result.error);
          else {
            getReport(id)
              .then((data) =>
                setReports((state) =>
                  state.map((report) => {
                    if (report.id === data.id) return data;
                    return report;
                  })
                )
              )
              .catch((err) => console.error(err.message));
            resolve();
          }
        });
      });
    },
    [getReport]
  );

  // Removes reports from the current array if they no longer fit the selected
  // filter.
  const applyCurrentFilter = useCallback(
    (report: Report): boolean => {
      if (filter === 'archived' && !report.metadata.archived) {
        return false;
      }
      if (
        (filter === 'active' || filter === 'active-approved') &&
        report.metadata.archived
      ) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const setArchived = useCallback(
    (id, archive) => {
      return new Promise<Report>((resolve, reject) => {
        const handleResponse = (response: IResponse<ReportResponse>) => {
          if (response.error) reject(response.error);
          else {
            const { data } = response;
            setReports((state) =>
              state
                .map((report) => {
                  if (report.id === data.id) return data;
                  return report;
                })
                .filter(applyCurrentFilter)
            );
            resolve(data);
          }
        };

        if (archive) reportService.Archive(id).then(handleResponse);
        else reportService.Unarchive(id).then(handleResponse);
      });
    },
    [applyCurrentFilter]
  );

  const archiveReport = useCallback(
    (id) => setArchived(id, true),
    [setArchived]
  );

  const unarchiveReport = useCallback(
    (id) => setArchived(id, false),
    [setArchived]
  );

  const deleteReport = useCallback((id) => {
    return new Promise<DeleteReportResponse>((resolve, reject) => {
      reportService.Delete(id).then((response) => {
        if (response.error) reject(response.error);
        else {
          if (response.success) {
            setReports((state) => state.filter((report) => report.id !== id));
          }
          resolve(response);
        }
      });
    });
  }, []);

  const downloadReport = useCallback(
    async (id: string, name: string) => {
      if (!user?.company) return Promise.reject(Error('User is null'));
      const response = await Aws.getCompanyFile(
        `${user.company.id}/reports/${id}/report.pdf`
      );
      if (response === null) {
        return Promise.reject(Error("report file doesn't exist"));
      }
      return new File(
        [Buffer.from((response.Body as Uint8Array).buffer)],
        name,
        { type: 'application/pdf' }
      );
    },
    [user?.company]
  );

  const clearState = useCallback(() => {
    setReports([]);
  }, []);

  return (
    <ReportContext.Provider
      value={{
        reports,
        fetchReports,
        createReport,
        cloneReport,
        getReport,
        saveReport,
        uploadImage,
        getImage,
        draftReport,
        approveReport,
        archiveReport,
        unarchiveReport,
        deleteReport,
        downloadReport,
        clearState,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export function useReport(): ReportContextData {
  const context = useContext(ReportContext);
  if (!context) {
    throw new Error('useReport must be used within a ReportProvider');
  }
  return context;
}
