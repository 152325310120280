import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { sentry, stack } from './config';
import AppContext from './providers';
import Routes from './routes';
import GlobalStyles from './styles/global';
import theme from './styles/theme';

const App: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    if (history && process.env.NODE_ENV !== 'development') {
      const browserTracing = new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      });

      Sentry.init({
        release: process.env.REACT_APP_RELEASE_COMMIT,
        dsn: sentry.dsn,
        integrations: [browserTracing],
        tracesSampleRate: 1,
        environment: stack,
      });
    }
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <AppContext>
        <Routes />
        <GlobalStyles />
      </AppContext>
    </ThemeProvider>
  );
};

const AppWrapper: React.FC = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;
