import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import { Mutex } from 'async-mutex';
import {
  AnalyticsChartsType,
  SectionDefinition,
  replaceReportingCurrency,
} from 'common-utils';
import _ from 'lodash';

import { analyticsService } from '../services';

interface AnalyticsContextData {
  getAnalyticsCharts: (
    language: string,
    type: AnalyticsChartsType,
    currency: string
  ) => Promise<SectionDefinition[]>;
}

const AnalyticsContext = createContext<AnalyticsContextData>(
  {} as AnalyticsContextData
);

// Cache the results of `getAnalyticsCharts`.
let analytics: Record<string, SectionDefinition[] | null> = {};
const analyticsMutex = new Mutex();

export const AnalyticsProvider: React.FC = ({ children }) => {
  const key = useCallback((language: string, type: string) => {
    return `${language}-${type}`;
  }, []);

  const chartsFor = useCallback(
    async (
      language: string,
      type: AnalyticsChartsType,
      currency: string
    ): Promise<SectionDefinition[] | null> => {
      const result = analytics[key(language, type)];
      if (result !== undefined) {
        return result;
      }

      const response = await analyticsService.GetAnalyticsCharts(
        language,
        type
      );

      if (response.error) {
        _.set(analytics, key(language, type), null);
        return null;
      }

      _.set(
        analytics,
        key(language, type),
        replaceReportingCurrency(response, currency)
      );
      return analytics[key(language, type)];
    },
    [key]
  );

  const getAnalyticsCharts = useCallback(
    async (language, type, currency) => {
      return new Promise<SectionDefinition[]>((resolve, reject) => {
        analyticsMutex
          .runExclusive(async () => {
            let result = await chartsFor(language, type, currency);

            if (!result) {
              result = await chartsFor('en', type, currency);
            }

            return result ?? [];
          })
          .then(resolve)
          .catch(reject);
      });
    },
    [chartsFor]
  );

  useEffect(() => {
    return () => {
      analyticsMutex.runExclusive(async () => {
        analytics = {};
      });
    };
  });

  return (
    <AnalyticsContext.Provider value={{ getAnalyticsCharts }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useAnalytics(): AnalyticsContextData {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
}
