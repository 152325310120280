import Api from '../api';

import { Acronym, Definition, ExternalResource, Standard } from './types';

class GlossaryService extends Api {
  async ListDefinitions(language: string) {
    return this.doRequest<Definition[]>({
      endpoint: `/definitions/${language}`,
    });
  }

  async ListAcronyms(language: string) {
    return this.doRequest<Acronym[]>({
      endpoint: `/acronyms/${language}`,
    });
  }

  async ListResources(language: string) {
    return this.doRequest<ExternalResource[]>({
      endpoint: `/external-resources/${language}`,
    });
  }

  async ListStandards(language: string) {
    return this.doRequest<Standard[]>({
      endpoint: `/standards/${language}`,
    });
  }
}

export const glossaryService = new GlossaryService();
