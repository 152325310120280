import React, { Suspense } from 'react';

import { AnalyticsProvider, useAnalytics } from './analytics';
import { AuthProvider, useAuth } from './auth';
import { CompanyProvider, useCompany } from './companies';
import { CurrencyProvider, useCurrency } from './currency';
import { DataRoomProvider, useDataRoom } from './dataroom';
import { DatasetProvider, useDataset } from './dataset';
import { ResourcesProvider, useResources } from './externalResources';
import { GlossaryProvider, useGlossary } from './glossary';
import { NavbarProvider, useNavbar } from './navbar';
import { QuestionnaireProvider, useQuestionnaire } from './questionnaire';
import { ReportProvider, useReport } from './report';
import { SASBProvider, useSASB } from './sasb';
import { StandardsProvider, useStandards } from './standards';
import { StripeProvider, useStripe } from './stripe';
import { ToastProvider, useToast } from './toast';
import { UserProvider, useUsers } from './users';
import { VerificationProvider, useVerification } from './verification';

const AppContext: React.FC = ({ children }) => (
  <Suspense fallback={<></>}>
    <ToastProvider>
      <AuthProvider>
        <StripeProvider>
          <UserProvider>
            <CurrencyProvider>
              <CompanyProvider>
                <NavbarProvider>
                  <DataRoomProvider>
                    <VerificationProvider>
                      <SASBProvider>
                        <AnalyticsProvider>
                          <QuestionnaireProvider>
                            <DatasetProvider>
                              <ReportProvider>
                                <GlossaryProvider>
                                  <ResourcesProvider>
                                    <StandardsProvider>
                                      {children}
                                    </StandardsProvider>
                                  </ResourcesProvider>
                                </GlossaryProvider>
                              </ReportProvider>
                            </DatasetProvider>
                          </QuestionnaireProvider>
                        </AnalyticsProvider>
                      </SASBProvider>
                    </VerificationProvider>
                  </DataRoomProvider>
                </NavbarProvider>
              </CompanyProvider>
            </CurrencyProvider>
          </UserProvider>
        </StripeProvider>
      </AuthProvider>
    </ToastProvider>
  </Suspense>
);

export default AppContext;
export {
  useAnalytics,
  useAuth,
  useCompany,
  useCurrency,
  useDataRoom,
  useDataset,
  useGlossary,
  useNavbar,
  useQuestionnaire,
  useReport,
  useResources,
  useSASB,
  useStripe,
  useToast,
  useUsers,
  useVerification,
  useStandards,
};
