import React from 'react';

import { ToastProps } from '../../providers/types';
import Toast from '../Toast/Toast';

import { Container } from './sToastContainer';

interface ToastContainerProps {
  messages: ToastProps[];
}

const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  return (
    <Container>
      {messages.map(({ id, type, index, message, removeToast }) => (
        <Toast
          key={id}
          id={id}
          type={type}
          index={index}
          message={message}
          removeToast={removeToast}
        />
      ))}
    </Container>
  );
};

export default ToastContainer;
