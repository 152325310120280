import React, { createContext, useCallback, useContext } from 'react';

import { Mutex } from 'async-mutex';
import _ from 'lodash';

import { companyService } from '../services';
import { Company } from '../services/companies/types';

interface CompanyContextData {
  getCompany: (id: string) => Promise<Company | null>;
}

const CompanyContext = createContext<CompanyContextData>(
  {} as CompanyContextData
);

// Cache the results of `getCompany`.
const companies: Record<string, Company> = {};
const companiesMutex = new Mutex();

export const CompanyProvider: React.FC = ({ children }) => {
  const getCompany = useCallback(async (id) => {
    return new Promise<Company | null>((resolve, reject) => {
      companiesMutex
        .runExclusive(async () => {
          const company = companies[id];
          if (company !== undefined) {
            return company;
          }

          const response = await companyService.Get(id);
          if (response.error) {
            _.set(companies, id, null);
            return null;
          }

          _.set(companies, id, response);
          return response;
        })
        .then(resolve)
        .catch(reject);
    });
  }, []);

  return (
    <CompanyContext.Provider value={{ getCompany }}>
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext);
  if (!context) {
    throw new Error('useCompany must be used within a CompanyProvider');
  }
  return context;
}
