import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    primary: '#12A8FC',
    primary_1: '#046497',
    primary_2: '#0867CD',
    primary_4: '#DDEFFF',
    primary_light: '#1E3228',
    secondary: '#141E30',
    secondary_background: '#F1F1F1',
    secondary_light: '#F6F6F6',
    secondary_dark: '#081A11',

    blue: '#57ADE3',
    blue_dark_1: '#06293F',
    blue_dark_2: '#21648E',
    brown: '#794E48',
    gray: '#CDCDCD',
    gray_0: '#212121',
    gray_1: '#4A4A4A',
    gray_2: '#68716D',
    gray_3: '#828E88',
    gray_4: '#A2A2A2',
    gray_5: '#C1C6C4',
    gray_6: '#E1E3E2',
    green: '#2DDA77',
    green_dark_1: '#188747',
    green_dark_2: '#1EB55F',
    green_light_2: '#7EEAAE',
    orange: '#F09A00',
    red: '#FD2935',
    red_dark: '#D02F44',
    salmon: '#FF7452',
    salmon_dark: '#B9533A',
    yellow: '#FFB126',

    table_border: '#CDCDCD',
    question_background: '#E3E3E3',
    header_background: '#FFFFFF',
    toast: {
      info: '#046497',
      success: '#208F4F',
      warning: '#FFB126',
      error: '#D02F44',
    },
    warningBanner: {
      warning: '#FFF0CD',
      error: '#FFD1D4',
    },
  },
};

export default theme;
