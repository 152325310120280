import Api from '../api';

import {
  CloneReportParams,
  DeleteReportResponse,
  ListReportParams,
  Report,
  ReportResponse,
  SaveReportParams,
  ShareReportParams,
} from './types';

class ReportService extends Api {
  async CreateReport(
    data: Omit<Report, 'id' | 'userId' | 'companyId' | 'datasetMetadata'>
  ) {
    return this.doRequest<ReportResponse>({
      endpoint: '/reports',
      data,
      method: 'POST',
    });
  }

  async CloneReport(reportId: string, data: CloneReportParams) {
    return this.doRequest<ReportResponse>({
      endpoint: `/reports/?originalReportId=${reportId}`,
      data,
      method: 'POST',
    });
  }

  async SaveReport(id: string, data: SaveReportParams) {
    return this.doRequest<ReportResponse>({
      endpoint: `/reports/${id}`,
      data,
      method: 'PUT',
    });
  }

  async Archive(id: string) {
    return this.doRequest<ReportResponse>({
      endpoint: `/reports/${id}/archive`,
      method: 'PUT',
    });
  }

  async Unarchive(id: string) {
    return this.doRequest<ReportResponse>({
      endpoint: `/reports/${id}/unarchive`,
      method: 'PUT',
    });
  }

  async Delete(id: string) {
    return this.doRequest<DeleteReportResponse>({
      endpoint: `reports/${id}`,
      method: 'DELETE',
    });
  }

  async List(data: ListReportParams) {
    return this.doRequest<Report[]>({
      endpoint: '/reports',
      data,
      method: 'GET',
    });
  }

  async GetReport(id: string) {
    return this.doRequest<Report>({
      endpoint: `/reports/${id}`,
    });
  }

  async DraftReport(id: string) {
    return this.doRequest<string>({
      endpoint: `/reports/draft/${id}`,
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'arraybuffer',
      },
    });
  }

  async ApproveReport(id: string) {
    return this.doRequest({
      endpoint: `/reports/approve/${id}`,
      method: 'PUT',
    });
  }

  async ShareReport(id: string, data: ShareReportParams) {
    return this.doRequest<{ success: boolean }>({
      endpoint: `/reports/${id}/share`,
      data,
      method: 'POST',
    });
  }

  async GetImage(reportId: string, imageId: string) {
    return this.doRequest<string>({
      endpoint: `/reports/image/${reportId}/${imageId}`,
    });
  }

  async PutImage(reportId: string, imageId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return this.doRequest({
      endpoint: `/reports/image/${reportId}/${imageId}`,
      method: 'PUT',
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
      data: formData,
    });
  }

  async NotifyApprovers(id: string) {
    return this.doRequest({
      endpoint: `/reports/${id}/notify`,
      method: 'PUT',
    });
  }
}

export const reportService = new ReportService();
