import React, { createContext, useCallback, useContext, useState } from 'react';

import { QuestionnaireMetadata } from 'common-utils';
import _ from 'lodash';

import { questionnaireService } from '../services';
import { IResponse } from '../services/api';
import {
  CreateQuestionnaireParams,
  UpdateQuestionnaireParams,
} from '../services/questionnaires/types';

interface FetchQuestionnaireParams {
  reload?: boolean;
}

interface QuestionnaireContextData {
  questionnaires: QuestionnaireMetadata[];
  fetchQuestionnaires: (params?: FetchQuestionnaireParams) => Promise<void>;
  createQuestionnaire: (
    params: CreateQuestionnaireParams
  ) => Promise<QuestionnaireMetadata>;
  updateQuestionnaire: (
    id: string,
    params: UpdateQuestionnaireParams
  ) => Promise<void>;
  archiveQuestionnaire: (id: string) => Promise<QuestionnaireMetadata>;
  unarchiveQuestionnaire: (id: string) => Promise<QuestionnaireMetadata>;
  getLatestVersion: (id: string, language: string) => Promise<string>;
  getLatestVersions: (id: string[], language: string) => Promise<string[]>;
  clearState: () => void;
}

const QuestionnaireContext = createContext<QuestionnaireContextData>(
  {} as QuestionnaireContextData
);

export const QuestionnaireProvider: React.FC = ({ children }) => {
  const [questionnaires, setQuestionnaires] = useState<QuestionnaireMetadata[]>(
    []
  );

  const fetchQuestionnaires = useCallback(
    async (params?: FetchQuestionnaireParams) => {
      const result = await questionnaireService.List({
        limit: 50,
        offset: params?.reload ? 0 : questionnaires?.length ?? 0,
      });
      if (result.error) throw result.error;
      setQuestionnaires((state) =>
        params?.reload ? result : _.unionBy(state, result, 'id')
      );
    },
    [questionnaires?.length]
  );

  const createQuestionnaire = useCallback(async (data) => {
    const result = await questionnaireService.Create(data);
    if (result.error) throw result.error;

    setQuestionnaires((state) => [result, ...state]);
    return result;
  }, []);

  const updateQuestionnaire = useCallback(async (id, data) => {
    const response = await questionnaireService.Update(id, data);
    if (response.error) throw response.error;

    setQuestionnaires((state) =>
      state.map((q) => {
        if (q.id === response.id) return response;
        return q;
      })
    );
  }, []);

  const setArchived = useCallback((id, archive) => {
    return new Promise<QuestionnaireMetadata>((resolve, reject) => {
      const handleResponse = (response: IResponse<QuestionnaireMetadata>) => {
        if (response.error) reject(response.error);
        else {
          setQuestionnaires((state) =>
            state.map((q) => {
              if (q.id === response.id) return response;
              return q;
            })
          );
          resolve(response);
        }
      };

      if (archive) questionnaireService.Archive(id).then(handleResponse);
      else questionnaireService.Unarchive(id).then(handleResponse);
    });
  }, []);

  const archiveQuestionnaire = useCallback(
    (id) => setArchived(id, true),
    [setArchived]
  );
  const unarchiveQuestionnaire = useCallback(
    (id) => setArchived(id, false),
    [setArchived]
  );

  const getLatestVersion = useCallback(async (id: string, language: string) => {
    const response = await questionnaireService.GetLatestVersion(id, language);
    if (response.error) {
      throw response.error;
    }
    return response.id;
  }, []);

  const getLatestVersions = useCallback(
    async (ids: string[], language: string) => {
      const result: string[] = [];
      for (let i = 0; i < ids.length; i += 1) {
        try {
          // eslint-disable-next-line no-await-in-loop
          result[i] = await getLatestVersion(ids[i], language);
        } catch (error) {
          console.error('failed to retrieve latest version', error);
          result[i] = ids[i];
        }
      }
      return result;
    },
    [getLatestVersion]
  );

  const clearState = useCallback(() => {
    setQuestionnaires([]);
  }, []);

  return (
    <QuestionnaireContext.Provider
      value={{
        questionnaires,
        fetchQuestionnaires,
        createQuestionnaire,
        updateQuestionnaire,
        archiveQuestionnaire,
        unarchiveQuestionnaire,
        getLatestVersion,
        getLatestVersions,
        clearState,
      }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};

export function useQuestionnaire(): QuestionnaireContextData {
  const context = useContext(QuestionnaireContext);
  if (!context) {
    throw new Error(
      'useQuestionnaire must be used within a QuestionnaireProvider'
    );
  }
  return context;
}
