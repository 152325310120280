import React, { createContext, useCallback, useContext, useState } from 'react';

import { currencyService } from '../services';
import { Currency } from '../services/currencies/types';

interface CurrencyContextData {
  currencies?: Currency[];
  fetchCurrencies: () => Promise<Currency[]>;
}

const CurrencyContext = createContext<CurrencyContextData>(
  {} as CurrencyContextData
);

export const CurrencyProvider: React.FC = ({ children }) => {
  const [currencies, setCurrencies] = useState<Currency[]>();

  const fetchCurrencies = useCallback(() => {
    return new Promise<Currency[]>((resolve, reject) => {
      currencyService.ListCurrencies().then((response) => {
        if (response.error) reject(response.error);
        else {
          setCurrencies(response);
          resolve(response);
        }
      });
    });
  }, []);

  return (
    <CurrencyContext.Provider value={{ currencies, fetchCurrencies }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export function useCurrency(): CurrencyContextData {
  const context = useContext(CurrencyContext);
  if (!context) {
    throw new Error('useCurrency must be used within an CurrencyProvider');
  }
  return context;
}
