import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { stripeService } from '../services';
import {
  BillingDetails,
  GetInvoicesResponse,
  GetPlanResponse,
  PaymentMethod,
} from '../services/stripe/types';
import { isCardExpired } from '../utils/stripe';

import { useAuth } from './auth';
import { User } from './types';

interface UpdateBillingDetailsParams {
  email: string;
  userName: string;
  companyName: string;
  city: string;
  country: string;
  address: string;
  postalCode: string;
  taxIdType: string;
  taxId: string;
}

type Invoices = GetInvoicesResponse | null;
export type Plan = GetPlanResponse | null;

interface StripeContextData {
  billingDetails?: BillingDetails | null;
  updateBillingDetails: (
    companyId: string,
    data: UpdateBillingDetailsParams
  ) => Promise<void>;

  // `null` if the customer doesn't have a subscription
  invoices?: Invoices;
  plan?: Plan;

  // `null` if the customer doesn't have a default payment method
  defaultPaymentMethod?: PaymentMethod | null;
  isExpired: boolean;
  setDefaultPaymentMethod: (id: string) => Promise<void>;

  paymentMethods?: PaymentMethod[];
  deletePaymentMethod: (paymentMethodId: string) => Promise<void>;

  subscribeToPlan: (
    priceId: string,
    coupon?: string,
    withDiscount?: boolean
  ) => Promise<void>;
  applyCoupon: (coupon: string) => Promise<void>;

  clearState: () => void;
}

const StripeContext = createContext<StripeContextData>({} as StripeContextData);

export const StripeProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const [billingDetails, setBillingDetails] = useState<BillingDetails | null>();

  const [invoices, setInvoices] = useState<Invoices>();
  const [plan, setPlan] = useState<Plan>();

  // All customer's payment methods
  const [paymentMethods, setPaymentMethods] = useState<
    PaymentMethod[] | undefined
  >();

  // Customer's default payment method (automatically charged)
  const [defaultPaymentMethod, setDefaultMethod] = useState<
    PaymentMethod | null | undefined
  >();
  const [isExpired, setExpired] = useState(false);

  const fetchBillingDetails = useCallback(async (user: User) => {
    const { id } = user.company;
    const result = await stripeService.GetBillingDetails(id);
    if (result.error) {
      throw result.error;
    }

    setBillingDetails(result);
  }, []);

  const fetchInvoices = useCallback(async (user: User) => {
    const { id } = user.company;
    const invoices = await stripeService.GetInvoices(id);
    if (invoices.error) {
      throw invoices.error;
    }

    setInvoices(invoices);
  }, []);

  const fetchPlan = useCallback(async (user: User) => {
    const { id } = user.company;
    const plan = await stripeService.GetPlan(id);
    if (plan.error) {
      throw plan.error;
    }

    setPlan(plan);
  }, []);

  const fetchPaymentMethods = useCallback(async (user: User) => {
    const { id } = user.company;
    const result = await stripeService.ListPaymentMethods(id);
    if (result.error || !result) {
      throw result.error;
    }

    setPaymentMethods(result.data);
  }, []);

  const fetchDefaultPaymentMethod = useCallback(async (user: User) => {
    const { id } = user.company;
    const result = await stripeService.GetActivePaymentMethod(id);
    if (result.error || !result) {
      throw result.error;
    }

    if (result.card) {
      setExpired(isCardExpired(result.card.expYear, result.card.expMonth));
    } else {
      setExpired(false);
    }

    setDefaultMethod(result);
  }, []);

  // Get the billing details
  useEffect(() => {
    if (
      billingDetails === undefined &&
      user?.company.id &&
      user?.role !== 'verifier'
    ) {
      fetchBillingDetails(user).catch(() => setBillingDetails(null));
    }
  }, [billingDetails, fetchBillingDetails, user]);

  // Get the invoice details
  useEffect(() => {
    if (
      invoices === undefined &&
      user?.company.id &&
      user?.role !== 'verifier'
    ) {
      fetchInvoices(user).catch(() => setInvoices(null));
    }
  }, [invoices, fetchInvoices, user]);

  // Get the company plan
  useEffect(() => {
    if (plan === undefined && user?.company.id && user?.role !== 'verifier') {
      fetchPlan(user).catch(() => setPlan(null));
    }
  }, [plan, fetchPlan, user]);

  // List the payment methods
  useEffect(() => {
    if (
      paymentMethods === undefined &&
      user?.company.id &&
      user?.role !== 'verifier'
    ) {
      fetchPaymentMethods(user).catch(() => setInvoices(null));
    }
  }, [paymentMethods, fetchPaymentMethods, user]);

  // Get the default payment method
  useEffect(() => {
    if (
      defaultPaymentMethod === undefined &&
      user?.company.id &&
      user?.role !== 'verifier'
    ) {
      fetchDefaultPaymentMethod(user).catch(() => setDefaultMethod(null));
    }
  }, [defaultPaymentMethod, fetchDefaultPaymentMethod, user]);

  const updateBillingDetails = useCallback(
    async (companyId: string, data: UpdateBillingDetailsParams) => {
      const {
        email,
        userName,
        companyName,
        city,
        country,
        address,
        postalCode,
        taxIdType,
        taxId,
      } = data;

      const result = await stripeService.UpdateBillingDetails(companyId, {
        name: companyName,
        email,
        metadata: { user_name: userName },
        address: {
          city,
          country,
          line1: address,
          postal_code: postalCode,
        },
        tax_ids: {
          data: [
            {
              type: taxIdType ?? 'eu_vat',
              value: taxId,
            },
          ],
        },
      });

      if (result.error) {
        throw result.error;
      }

      setBillingDetails(result);
    },
    []
  );

  const setDefaultPaymentMethod = useCallback(
    async (id: string) => {
      if (!user) {
        throw Error('User is undefined');
      }
      const { id: companyId } = user.company;

      return stripeService
        .SetDefaultPaymentMethod(companyId, id)
        .then((result) => {
          if (result.error) {
            throw result.error;
          }
          setDefaultMethod(undefined);
          setPaymentMethods(undefined);
        });
    },
    [user]
  );

  const deletePaymentMethod = useCallback(
    async (id: string) => {
      if (!user) {
        throw Error('User is undefined');
      }

      const { id: companyId } = user.company;

      return stripeService.DeletePaymentMethod(companyId, id).then((result) => {
        if (result.error) {
          throw result.error;
        }
        setDefaultMethod(undefined);
        setPaymentMethods(undefined);
      });
    },
    [user]
  );

  const reloadSubscriptionData = useCallback(
    async (user) => {
      fetchInvoices(user).catch(() => setInvoices(null));
      fetchPlan(user).catch(() => setPlan(null));
    },
    [fetchInvoices, fetchPlan]
  );

  const subscribeToPlan = useCallback(
    async (priceId, coupon, withDiscount) => {
      if (!user) {
        throw Error('User is undefined');
      }
      const { id } = user.company;

      const result = await stripeService.SubscribeToPlan(id, priceId, {
        coupon,
        withDiscount,
      });

      if (result.error) {
        throw result.error;
      }

      reloadSubscriptionData(user);
    },
    [user, reloadSubscriptionData]
  );

  const applyCoupon = useCallback(
    async (coupon: string) => {
      if (!user) {
        throw Error('User is undefined');
      }
      const { id } = user.company;

      if (plan?.subscription) {
        const subscriptionId = plan.subscription.id;

        const result = await stripeService.ApplyCoupon(
          id,
          subscriptionId,
          coupon
        );

        if (result.error) {
          throw result.error;
        }

        reloadSubscriptionData(user);
      }
    },
    [user, plan?.subscription, reloadSubscriptionData]
  );

  const clearState = useCallback(() => {
    setBillingDetails(undefined);
    setInvoices(undefined);
    setPlan(undefined);
    setPaymentMethods(undefined);
    setDefaultMethod(undefined);
  }, []);

  return (
    <StripeContext.Provider
      value={{
        billingDetails,
        updateBillingDetails,
        invoices,
        plan,
        defaultPaymentMethod,
        isExpired,
        setDefaultPaymentMethod,
        deletePaymentMethod,
        paymentMethods,
        subscribeToPlan,
        applyCoupon,
        clearState,
      }}
    >
      {children}
    </StripeContext.Provider>
  );
};

export function useStripe(): StripeContextData {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error('useStripe must be used within a StripeProvider');
  }
  return context;
}
