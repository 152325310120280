import { formatPriceValue } from 'common-utils';
import _ from 'lodash';

export { formatPriceValue };

export function capitalize(phrase: string): string {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function regexEscape(v: string): string {
  return v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function normalize(str: string): string {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export interface KeywordMatch {
  index: number;
  length: number;
  values: string[];
}

function improveMatches(
  search: string,
  keywords: string[]
): (s: string, idx: number) => string {
  function isFollowedBySpace(idx: number) {
    return idx < keywords.length - 1 || search[search.length - 1] === ' ';
  }

  return function f(s: string, idx: number): string {
    if (s.length > 1) return s;

    // Improve single character keyword matches by matching word boundaries.
    let result = `(^|\\b)${s}`;
    if (isFollowedBySpace(idx)) {
      result += `\\b`;
    }

    return result;
  };
}

function concatCloseMatches(matches: KeywordMatch[]): KeywordMatch[] {
  const result = [matches[0]];

  for (let i = 1; i < matches.length; i += 1) {
    const prev = result[result.length - 1];
    const dist = matches[i].index - (prev.index + prev.length);

    if (dist === 1 || dist === 0) {
      result[result.length - 1] = {
        index: prev.index,
        length: prev.length + matches[i].length + dist,
        values: _.concat(prev.values, matches[i].values),
      };
    } else {
      result.push(matches[i]);
    }
  }

  return result;
}

export type Syntax = 'text' | 'markdown';

// https://stackoverflow.com/questions/37462126/regex-match-markdown-link
const MDLinkRgxp = new RegExp(/\[([^[\]]*)\]\((.*?)\)/gm);
const MDLinkLabelRgxp = new RegExp(/\[([^[\]]*)\]/gm);
const MDLinkURLRgxp = new RegExp(/\((.*?)\)/gm);

function sanitizeMD(text: string): string {
  // Ignore links [SST-905]
  return text.replaceAll(MDLinkRgxp, (m) => {
    const label = [...m.matchAll(MDLinkLabelRgxp)][0][1];
    const link = [...m.matchAll(MDLinkURLRgxp)][0][0];
    return ` ${label} ${' '.repeat(link.length)}`;
  });
}

export function matchKeywords(
  text: string,
  search: string,
  syntax: Syntax = 'text'
): KeywordMatch[] {
  const keywords = normalize(search)
    .split(' ')
    .map(regexEscape)
    .filter((s) => s.length > 0);

  if (!keywords.length) {
    return [];
  }

  const keywordExp = new RegExp(
    keywords.map(improveMatches(search, keywords)).join('|'),
    'ig'
  );

  let value;
  switch (syntax) {
    case 'markdown':
      value = sanitizeMD(text);
      break;

    default:
      value = text;
  }

  const matches = [...normalize(value).matchAll(keywordExp)].map((m) => {
    return {
      index: m.index ?? 0,
      length: m[0].length,
      values: [...m.values()],
    };
  });

  if (!matches.length) {
    return matches;
  }

  return concatCloseMatches(matches);
}

// Wraps matches of the keywords in `text` with the string `wrapper`.
export function wrapKeywords(
  text: string,
  keywords: string,
  wrapper: string,
  syntax: Syntax = 'text'
): string {
  if (keywords === '') return text;

  const matches = matchKeywords(text, keywords, syntax);

  const result = matches.map((match, idx) => {
    let start = 0;
    if (idx > 0) {
      const prev = matches[idx - 1];
      start = prev.index + prev.length;
    }

    return (
      text.slice(start, match.index) +
      wrapper +
      text.slice(match.index, match.index + match.length) +
      wrapper
    );
  });

  let end = 0;
  if (matches.length) {
    const last = matches[matches.length - 1];
    end = last.index + last.length;
  }
  result.push(text.slice(end));

  return result.join('');
}

export function uid(): string {
  return Date.now().toString(32) + Math.random().toString(36).slice(2);
}

export function formatUserName(name?: string, lastName?: string): string {
  let result = name || '';
  if (lastName?.length) {
    result = result.concat(result.length ? ' ' : '').concat(lastName);
  }
  return result;
}

export function formatCreditCardNumber(brand: string, last4: string): string {
  return `${capitalize(brand)} **** ${last4}`;
}

interface Address {
  city: string;
  country: string;
  line1: string;
  postal_code: string;
}

export function formatAddress(address?: Address | null): string {
  if (!address) {
    return '-';
  }
  const { line1, postal_code, city } = address;
  return `${line1}, ${postal_code} ${city}`;
}

export const phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;
