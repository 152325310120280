import Api from '../api';

import {
  Company,
  CreateCompanyParams,
  SimpleCompany,
  UpdateCompanyParams,
} from './types';

class CompanyService extends Api {
  async Create(data: CreateCompanyParams) {
    return this.doRequest<{ companyId: string }>({
      endpoint: '/companies',
      data,
      method: 'POST',
    });
  }

  async Get(id: string) {
    return this.doRequest<Company>({
      endpoint: `/companies/${id}`,
    });
  }

  async Update(id: string, data: UpdateCompanyParams) {
    return this.doRequest<Company>({
      endpoint: `/companies/${id}`,
      data,
      method: 'PUT',
    });
  }

  async List() {
    return this.doRequest<SimpleCompany[]>({
      endpoint: `/companies`,
    });
  }
}

export const companyService = new CompanyService();
