import { TFunction } from 'i18next';
import _ from 'lodash';

import { Option } from '../components/inputs/Input/Input';

export const listYears = (): string[] => {
  const end = new Date().getUTCFullYear();
  return _.rangeRight(1970, end + 1).map(_.toString);
};

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
];

export const dateFormats = [
  'D Mon YYYY',
  'D Month YYYY',
  'Mon D, YYYY',
  'Month D, YYYY',
  'DD MM YYYY',
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY/MM/DD',
];

export const formatMonth = (
  t: TFunction,
  date: Date,
  fullMonth = false
): string => {
  return fullMonth
    ? t(`common:months-full.${months[date.getMonth()]}`)
    : t(`common:months.${months[date.getMonth()]}`);
};

export const formatDate = (
  t: TFunction,
  date?: Date | string | null,
  dateFormat?: string
): string => {
  if (date === undefined || date === '' || date === null) return '-';
  if (typeof date === 'string') date = new Date(date);

  let format: string = dateFormat ?? dateFormats[0];
  format = format.replace('DD', String(date.getDate()).padStart(2, '0'));
  format = format.replace('D', `${date.getDate()}`);

  format = format.replace('MM', String(date.getMonth() + 1).padStart(2, '0'));
  format = format.replace('Month', formatMonth(t, date, true));
  format = format.replace('Mon', formatMonth(t, date));

  format = format.replace('YYYY', `${date.getFullYear()}`);

  return format;
};

export const formatDayMonth = (t: TFunction, date: Date): string => {
  return `${date.getDate()} ${formatMonth(t, date)}`;
};

export const formatDateTime = (
  t: TFunction,
  date: Date,
  dateFormat?: string
): string => {
  return `${formatDate(t, date, dateFormat)} - ${date.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })}`;
};

export const formatDateRange = (
  t: TFunction,
  start?: Date | string | null,
  end?: Date | string | null,
  dateFormat?: string
): string => {
  if (
    (start === undefined || start === null) &&
    (end === undefined || end === null)
  ) {
    return '-';
  }

  return `${formatDate(t, start, dateFormat)} - ${formatDate(
    t,
    end,
    dateFormat
  )}`;
};

export const getDateFormatOptions = (t: TFunction): Option[] =>
  dateFormats.map((format) => ({
    value: format,
    label: `${format
      .replace('Month', t('common:month'))
      .replace('Mon', t('common:month-abrev'))} - ${formatDate(
      t,
      new Date(),
      format
    )}`,
  }));
