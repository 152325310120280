import { AnalyticsChartsType, SectionDefinition } from 'common-utils';

import Api from '../api';

class AnalyticsService extends Api {
  async GetAnalyticsCharts(language: string, type: AnalyticsChartsType) {
    return this.doRequest<SectionDefinition[]>({
      endpoint: `/charts/${type}/${language}`,
    });
  }
}

export const analyticsService = new AnalyticsService();
