import {
  DecimalFormat,
  QuestionExpressionParser as Parser,
  defaultAnswer,
  defaultMultiSelectAnswer,
  formatAnswerValue,
  questionCompanyFilter,
  sortedDimensionCodes,
} from 'common-utils';
import _ from 'lodash';
import { TFunction } from 'react-i18next';

import {
  Answer,
  Approval,
  Dataset,
  DisclosureTopic,
  SustainabilityDimension,
  Verification,
} from '../services/datasets/types';
import {
  Question as IQuestion,
  QuestionDatatype,
  Table,
  YearlyFormula,
} from '../services/questionnaires/types';

import { average, parseCompanyValue, sum } from './number';

export { questionCompanyFilter };
export { deduplicate } from 'common-utils';

interface PreviousAnswer {
  year: string;
  value: string;
  isConfidential: boolean;
}

export interface Question extends IQuestion {
  answer?: Answer;
  approval?: Approval;
  verification?: Verification;
  previousAnswers: PreviousAnswer[];
  year: string;
  sustainabilityDimensionName: string;
  disclosureTopicName: string;
  questionnaires: {
    name: string;
    type: string;
    iconURL: string;
  }[];
}

const byCode = (code: string) => (value: { code: string }) =>
  code === value.code;

interface GetAndFormatPreviousAnswersForQuestionParams {
  code: string;
  datatype: QuestionDatatype;
  includedDatasets: Dataset[];
  table?: Table;
  decimalFormat: DecimalFormat;
  t: TFunction;
}

function getAndFormatPreviousAnswersForQuestion(
  params: GetAndFormatPreviousAnswersForQuestionParams
): PreviousAnswer[] {
  const { datatype, table, decimalFormat, t } = params;

  const previousAnswers = _.chain(params.includedDatasets)
    .map((set) => {
      const answer = set.answers?.find(byCode(params.code));

      const value = formatAnswerValue({
        value: answer?.value,
        datatype,
        table,
        decimalFormat,
        t,
      });

      return {
        year: set?.metadata.fiscalYear ?? '-',
        value: (value as string) ?? '-',
        isConfidential: answer?.isConfidential ?? false,
      };
    })
    .sortBy('year')
    .value();

  return previousAnswers;
}

export function filterQuestionsWithExpressionParser<
  T extends {
    code: string;
    datatype: QuestionDatatype;
    answer?: Answer;
    expressions?: {
      hide?: string;
    };
  }
>(questions: T[]): T[] {
  const answers = questions
    .filter(
      ({ answer }) =>
        answer && typeof answer.value === 'string' && answer.value !== ''
    )
    .map(({ answer }) => answer) as Answer[];

  const parser = new Parser(answers);

  const filteredQuestions = questions.filter((question) => {
    if (question.expressions?.hide) {
      const hide = parser.evaluate(question.expressions.hide);
      return !hide;
    }
    return true;
  });

  return filteredQuestions;
}

interface QuestionWithQuestionnaireData extends IQuestion {
  questionnaires: {
    name: string;
    type: string;
    iconURL: string;
  }[];
}

interface AssembleDatasetParams {
  dataset: Dataset;
  includedDatasets?: Dataset[];
  questions: QuestionWithQuestionnaireData[];
  tables?: Table[];
  topics: DisclosureTopic[];
  dimensions: SustainabilityDimension[];
  currency: string;
  decimalFormat?: DecimalFormat;
  t: TFunction;
  formatAnswerValues?: boolean;
  includeAnswers?: boolean;
  includeApprovals?: boolean;
  includeVerifications?: boolean;
  filterWithExpressionParser?: boolean;
}

// Add the answer, approval, sustainability dimension and disclosure topic for
// each question.
//
// If `formatAnswerValues` is true, numerical answers from the main dataset
// will be formated in the company defined format, table answers will be
// replaced by the value, and boolean answers will be translated.
// Answers from included datasets are always formated.
//
// Also replaces 'Reporting currency' unit by `currency`.
export function assembleDataset({
  dataset,
  includedDatasets = [],
  questions,
  tables = [],
  topics,
  dimensions,
  currency,
  decimalFormat = 'period',
  t,
  formatAnswerValues = true,
  includeAnswers = true,
  includeApprovals = true,
  includeVerifications = true,
  filterWithExpressionParser = false,
}: AssembleDatasetParams): Question[] {
  const questionsWithAnswer: Question[] = questions.map((question) => {
    const { code: topicCode } = question.disclosureTopic;
    const topic = topics.find(byCode(topicCode));

    const { code: dimCode } = question.sustainabilityDimension;
    const dimension = dimensions.find(byCode(dimCode));

    // Find the answer, approval and verification for this question
    const answer = includeAnswers
      ? _.cloneDeep(dataset.answers?.find(byCode(question.code)))
      : undefined;

    const approval = includeApprovals
      ? _.cloneDeep(dataset.approvals?.find(byCode(question.code)))
      : undefined;

    const verification = includeVerifications
      ? _.cloneDeep(dataset.verifications?.find(byCode(question.code)))
      : undefined;

    let table: Table | undefined;
    if (question.datatype === 'MultiSelect') {
      table = tables.find(({ name }) => name === question.optionsTableName);
    }

    const previousAnswers = getAndFormatPreviousAnswersForQuestion({
      code: question.code,
      datatype: question.datatype,
      includedDatasets,
      table,
      decimalFormat,
      t,
    });

    const unit =
      question.unit === 'Reporting currency' ? currency : question.unit;

    return {
      ...question,
      topic,
      disclosureTopicName: topic?.name || '',
      sustainabilityDimensionName: dimension?.name || '',
      year: dataset.metadata.fiscalYear,
      answer,
      approval,
      verification,
      previousAnswers,
      unit,
    };
  });

  let filteredQuestions = questionsWithAnswer.filter(
    questionCompanyFilter(dataset.metadata.company)
  );

  if (filterWithExpressionParser) {
    filteredQuestions = filterQuestionsWithExpressionParser(filteredQuestions);
    // Also include hidden questions that have an answer (automatic calculation).
    const filteredQuestionCodes = filteredQuestions.map(({ code }) => code);
    const answerCodes = dataset.answers?.map(({ code }) => code);
    const notIncludedQuestionsWithAnswer = _.difference(
      answerCodes,
      filteredQuestionCodes
    );
    notIncludedQuestionsWithAnswer.forEach((code) => {
      const question = questionsWithAnswer.find(
        ({ code: qCode }) => qCode === code
      );
      if (question) {
        filteredQuestions.push(question);
      }
    });
  }

  let result = filteredQuestions;
  if (formatAnswerValues) {
    result = filteredQuestions.map((question) => {
      const answer = _.cloneDeep(question.answer);

      if (answer !== undefined) {
        let table: Table | undefined;
        if (question.datatype === 'MultiSelect') {
          table = tables.find(({ name }) => name === question.optionsTableName);
        }

        answer.value = formatAnswerValue({
          value: answer?.value,
          datatype: question.datatype,
          table,
          decimalFormat,
          t,
        }) as string;
      }

      return { ...question, answer };
    });
  }

  _.sortBy(result, 'index');
  return result;
}

export function sortDimensions<T extends { code: string }>(dims: T[]): T[] {
  const compareTo = (a: T, b: T) => {
    const oA = sortedDimensionCodes.indexOf(a.code);
    const oB = sortedDimensionCodes.indexOf(b.code);
    return oB - oA;
  };

  return dims.slice().sort(compareTo);
}

// Groups and sorts an array of questions by Sustainability Dimension.
export function groupAndSort<
  T extends {
    sustainabilityDimension: { code: string };
    sustainabilityDimensionName: string;
  }
>(questions: T[]): T[][] {
  const dimensions = Object.values(
    _.groupBy(questions, 'sustainabilityDimensionName')
  );

  const compareTo = (a: T[], b: T[]) => {
    const oA = sortedDimensionCodes.indexOf(a[0].sustainabilityDimension.code);
    const oB = sortedDimensionCodes.indexOf(b[0].sustainabilityDimension.code);
    return oB - oA;
  };

  dimensions.sort(compareTo);

  return dimensions;
}

type ApprovalStatus = 'approved' | 'not-completed' | 'pending';

export function getApprovalStatus(
  question: {
    code: string;
    answer?: Answer;
    approval?: Approval;
  },
  approvals: { code: string }[]
): ApprovalStatus {
  let isApproved = question.approval !== undefined;
  if (!isApproved) {
    isApproved = approvals.map((app) => app.code).includes(question.code);
  }
  if (isApproved) {
    return 'approved';
  }

  if (
    !question.answer ||
    (question.answer.value?.length === 0 && !question.answer.isDisabled)
  ) {
    return 'not-completed';
  }

  return 'pending';
}

export function questionWithCompanyValueToStandard<
  T extends { datatype: QuestionDatatype; answer?: Answer }
>(question: T, decimalFormat: DecimalFormat): T {
  if (
    question.datatype === 'Numeric' &&
    question.answer &&
    typeof question.answer.value === 'string' &&
    question.answer.value !== ''
  ) {
    const value = parseCompanyValue(question.answer.value, decimalFormat);

    if (value !== 'NaN') {
      return { ...question, answer: { ...question.answer, value } };
    }
  }
  return question;
}

// If a question is hidden from the questionnaire and doesn't have an
// `answer`, which means it doesn't have a default value expression and thus
// isn't an 'automatic calculation' question, it should be hidden from the
// Review section of the Dataset Editor and shouldn't count towards marking a
// dataset as approved.
export function isRelevantForApprovals({
  isHidden,
  answer,
  indicator,
}: {
  isHidden?: boolean;
  answer?: Answer;
  indicator: string;
}): boolean {
  return (
    indicator.length > 0 &&
    (!isHidden ||
      (answer !== undefined &&
        !_.isEqual(_.omit(answer, 'code'), defaultAnswer) &&
        !_.isEqual(_.omit(answer, 'code'), defaultMultiSelectAnswer)))
  );
}

export const yearlyFunctions: Record<
  YearlyFormula,
  (...v: number[]) => number
> = {
  AVG: average,
  SUM: sum,
};

export const annualTurnoverOptions = [
  { value: '≤ €2 m', label: '≤ €2 m' },
  { value: '≤ €10 m', label: '≤ €10 m' },
  { value: '≤ €50 m', label: '≤ €50 m' },
  { value: '> €50 m', label: '> €50 m' },
];

export const employeeNumberOptions = [
  { value: '≤ 10', label: '≤ 10' },
  { value: '≤ 50', label: '≤ 50' },
  { value: '≤ 250', label: '≤ 250' },
  { value: '> 250', label: '> 250' },
];
