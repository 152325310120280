import {
  Answer,
  Report,
  ReportAnswer,
  ReportMetadata,
  defaultMaterialityQuestionCode,
  defaultSDGQuestionCode,
  defaultStakeholdersQuestionCode,
} from 'common-utils';
import { TFunction } from 'i18next';
import _ from 'lodash';

import { DisclosureTopic } from '../services/datasets/types';

interface SectionCover {
  key: string;
  titleInput: string;
  titleOption: string;
}

export function defaultSectionCovers(name: string): SectionCover[] {
  return [
    {
      key: 'cover',
      titleInput: '',
      titleOption: name,
    },
    {
      key: 'company-overview',
      titleInput: '',
      titleOption: 'Company Overview',
    },
    {
      key: 'materiality',
      titleInput: '',
      titleOption: 'Materiality & Strategy',
    },
    {
      key: 'sust-performance',
      titleInput: '',
      titleOption: 'Sustainability Performance',
    },
    {
      key: 'annex',
      titleInput: '',
      titleOption: 'Annex',
    },
  ];
}

function defaultSDGAnswer(answers: Answer[]): ReportAnswer {
  const result = {
    code: 'q2',
    value: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    section: 'materiality',
    subsection: 'sust-dev-goals',
  };

  const answer = answers.find(({ code }) => code === defaultSDGQuestionCode);
  if (!answer) return result;

  (answer.value as string[]).forEach((code) => {
    const idx = _.toNumber(code.slice(3)) - 1;
    result.value[idx] = true;
  });

  return result;
}

function defaultStakeholdersAnswer(answers: Answer[]): ReportAnswer {
  const result: ReportAnswer = {
    code: 'q2',
    value: [],
    section: 'materiality',
    subsection: 'stakeholders',
  };

  // codes maps the relevant codes in the dataset answer to the corresponding
  // codes in the report.
  const codes: Record<string, string> = {
    employees: 'employees',
    investors: 'shareholders',
    government: 'government',
    customers: 'customers',
    suppliers: 'supply-chain',
    'local-community': 'community',
    media: 'media',
    'industry-groups': 'industry-groups',
    'bus-partners': 'bus-partners',
    universities: 'education',
    other: 'other',
  };

  const answer = answers.find(
    ({ code }) => code === defaultStakeholdersQuestionCode
  );
  if (!answer) return result;

  (answer.value as string[]).forEach((code) => {
    const rc = codes[code];
    if (rc) {
      (result.value as string[]).push(rc);
    }
  });

  return result;
}

function defaultMaterialityMatrixAnswer(
  answers: Answer[],
  topics: DisclosureTopic[],
  t: TFunction
): ReportAnswer {
  const result = {
    code: 'q2',
    value: {
      type: 'image',
      option: 'matrix',
      includeIssues: true,
    },
    section: 'materiality',
    subsection: 'esg-materiality',
  };

  const answer = answers.find(
    ({ code }) => code === defaultMaterialityQuestionCode
  );
  if (!answer) return result;

  // codes maps the relevant codes in the dataset answer to the corresponding
  // disclosure topic codes.
  const codes: Record<string, string> = {
    'governance-management': 'GM',
    ethics: 'ET',
    'legal-regulatory': 'LR',
    'critical-risk': 'CR',
    'systemic-risk': 'SR',
    'environmental-management': 'AM',
    'soil-contamination': 'CN',
    emissions: 'GH',
    energy: 'EM',
    waste: 'WH',
    water: 'WW',
    air: 'AQ',
    ecological: 'EE',
    'employment-creation': 'EC',
    'Labor Practices': 'LP',
    'health-safety': 'HS',
    'diversity-engagement': 'ED',
    'diversity-inclusion': 'DI',
    recruitment: 'DR',
    'social-capital': 'SM',
    sdg: 'SD',
    'human-rights ': 'HR',
    quality: 'QU',
    relations: 'CM',
    privacy: 'CP',
    security: 'DS',
    'selling-practices': 'SP',
    labeling: 'PL',
    chemicals: 'MC',
    'product-performance': 'PS',
    indigenous: 'IR',
    'supply-chain': 'SS',
    innovation: 'IM',
    resilience: 'BR',
    sourcing: 'MS',
    adaptation: 'CC',
    'product-lifecycle': 'PD',
  };

  const dimensions: Record<string, { topics: string[]; description: string }> =
    {
      BI: {
        topics: [],
        description: t('reports:materiality.default-values.BI'),
      },
      EN: {
        topics: [],
        description: t('reports:materiality.default-values.EN'),
      },
      HC: {
        topics: [],
        description: t('reports:materiality.default-values.HC'),
      },
      LG: {
        topics: [],
        description: t('reports:materiality.default-values.LG'),
      },
      SC: {
        topics: [],
        description: t('reports:materiality.default-values.SC'),
      },
    };

  (answer.value as string[]).forEach((code) => {
    const topicCode = codes[code];
    if (!topicCode) return;

    const topic = topics.find(({ code }) => code === topicCode);
    if (!topic) return;

    dimensions[topic.sustainabilityDimensionCode].topics.push(topicCode);
  }, {});

  _.set(result, 'value.dimensions', dimensions);
  return result;
}

function defaultReportAnswers(
  answers: Answer[],
  topics: DisclosureTopic[],
  t: TFunction
): ReportAnswer[] {
  return [
    {
      code: 'map',
      value: {
        checked: true,
        markets: [],
      },
      section: 'company-overview',
      subsection: 'comp-mark-pos',
    },
    defaultSDGAnswer(answers),
    defaultStakeholdersAnswer(answers),
    defaultMaterialityMatrixAnswer(answers, topics, t),
    {
      code: 'q2',
      value: {
        type: 'image',
        option: 'image',
        dimensions: {
          BI: {
            checked: true,
            strategy0: '',
            strategy1: '',
            objective0: '',
            objective1: '',
            description: '',
          },
          EN: {
            checked: true,
            strategy0: '',
            strategy1: '',
            objective0: '',
            objective1: '',
            description: '',
          },
          HC: {
            checked: true,
            strategy0: '',
            strategy1: '',
            objective0: '',
            objective1: '',
            description: '',
          },
          LG: {
            checked: true,
            strategy0: '',
            strategy1: '',
            objective0: '',
            objective1: '',
            description: '',
          },
          SC: {
            checked: true,
            strategy0: '',
            strategy1: '',
            objective0: '',
            objective1: '',
            description: '',
          },
        },
        colorSquare: false,
      },
      section: 'materiality',
      subsection: 'esg-strategy',
    },
    {
      code: 'lg-topics',
      value: { topics: [] },
      section: 'sust-performance',
      subsection: 'lg',
    },
    {
      code: 'en-topics',
      value: { topics: [] },
      section: 'sust-performance',
      subsection: 'en',
    },
    {
      code: 'hc-topics',
      value: { topics: [] },
      section: 'sust-performance',
      subsection: 'hc',
    },
    {
      code: 'sc-topics',
      value: { topics: [] },
      section: 'sust-performance',
      subsection: 'sc',
    },
    {
      code: 'bi-topics',
      value: { topics: [] },
      section: 'sust-performance',
      subsection: 'bi',
    },
  ];
}

export function defaultReportData(
  data: ReportMetadata,
  answers: Answer[],
  topics: DisclosureTopic[],
  t: TFunction
): Omit<Report, 'id' | 'userId' | 'companyId' | 'datasetMetadata'> {
  return {
    metadata: data,
    questions: [],
    subSections: [
      'intro-letter',
      'high-numbers',
      'org-overview',
      'activities',
      'bus-op-env',
      'comp-mark-pos',
      'risks',
      'sust-dev-goals',
      'stakeholders',
      'esg-materiality',
      'esg-strategy',
      'lg',
      'en',
      'hc',
      'sc',
      'bi',
      'kpi-tables',
      'ver-report',
    ],
    sectionCovers: defaultSectionCovers(data.name),
    answers: defaultReportAnswers(answers, topics, t),
  };
}
