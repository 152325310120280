import React, { createContext, useCallback, useContext, useState } from 'react';

import {
  QuestionnaireMetadata,
  byType,
  updateQuestionnaires,
} from 'common-utils';
import _ from 'lodash';

import {
  currencyService,
  datasetService,
  questionnaireService,
} from '../services';
import { IResponse } from '../services/api';
import {
  CloneDatasetParams,
  CreateDatasetParams,
  Dataset,
  DatasetMetadata,
  DatasetResponse,
  DatasetVerificationStatus,
  DeleteDatasetResponse,
  ListDatasetsFilter,
} from '../services/datasets/types';
import { Question as IQuestion, Table } from '../services/questionnaires/types';
import { deduplicate } from '../utils/dataset';

import { useAuth } from './auth';
import { useQuestionnaire } from './questionnaire';

export interface Question extends IQuestion {
  questionnaires: {
    name: string;
    type: string;
    iconURL: string;
  }[];
}

export interface GetQuestionnaireResult {
  metadata: QuestionnaireMetadata[];
  dataset: Dataset;
  questions: Question[];
  tables: Table[];
  currencySymbol: string;
  includedDatasets: Dataset[];
}

interface FetchDatasetsParams {
  search?: string; // Send search string to the API.
  filter?: string;
  clear?: boolean; // Replace existing entries.
}

interface GetQuestionnaireOptions {
  withIncludedDatasets?: boolean;
  withExcludedQuestions?: boolean;
}

interface DatasetContextData {
  datasets: DatasetMetadata[];
  fetchDatasets: (params?: FetchDatasetsParams) => Promise<void>;
  createDataset: (data: CreateDatasetParams) => Promise<DatasetMetadata>;
  cloneDataset: (data: CloneDatasetParams) => Promise<DatasetMetadata>;
  getDataset: (id: string) => Promise<Dataset>;
  getQuestionnaire: (
    dataset: Dataset,
    options?: GetQuestionnaireOptions
  ) => Promise<GetQuestionnaireResult>;
  saveDataset: (id: string, data: Dataset) => Promise<Dataset>;
  updateDatasetQuestionnaires: (id: string) => Promise<Dataset>;
  archiveDataset: (id: string) => Promise<Dataset>;
  unarchiveDataset: (id: string) => Promise<Dataset>;
  deleteDataset: (id: string) => Promise<DeleteDatasetResponse>;
  requestVerification: (id: string) => Promise<Dataset>;
  clearState: () => void;
}

const DatasetContext = createContext<DatasetContextData>(
  {} as DatasetContextData
);

export const DatasetProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const { getLatestVersions } = useQuestionnaire();

  const [datasets, setDatasets] = useState<DatasetMetadata[]>([]);
  const [filter, setFilter] = useState<string | undefined>();

  const fetchDatasets = useCallback(
    (params?: FetchDatasetsParams) => {
      setFilter(params?.filter);
      return new Promise<void>((resolve, reject) => {
        datasetService
          .List({
            limit: 50,
            offset: params?.clear ? 0 : datasets.length,
            companyId: user?.company.id,
            search: params?.search === '' ? undefined : params?.search,
            filter: params?.filter as ListDatasetsFilter,
          })
          .then((response) => {
            if (response.error) reject(response.error);
            else {
              const newDatasets = response.map((dataset) => dataset.metadata);
              setDatasets((state) =>
                params?.clear
                  ? newDatasets
                  : _.unionBy(state, newDatasets, 'id')
              );
              resolve();
            }
          });
      });
    },
    [datasets.length, user?.company.id]
  );

  const getDataset = useCallback((id) => {
    return new Promise<Dataset>((resolve, reject) => {
      datasetService.Get(id).then((response) => {
        if (response.error) reject(response.error);
        else resolve(response);
      });
    });
  }, []);

  // All the questions for a dataset, tables, the currency symbol and included
  // datasets.
  const getQuestionnaire = useCallback(
    (dataset: Dataset, options?: GetQuestionnaireOptions) => {
      const opt = {
        withIncludedDatasets: true,
        withExcludedQuestions: false,
        ...options,
      };

      return new Promise<GetQuestionnaireResult>((resolve, reject) => {
        Promise.all(
          dataset.questionnaireIds.map((id) => questionnaireService.Get(id))
        )
          .then(async (questionnaires) => {
            questionnaires.sort(byType);

            const rawQuestions = questionnaires
              .map((qtn) =>
                qtn.questions.map((q) => ({
                  ...q,
                  questionnaires: [
                    {
                      name: qtn.metadata.name,
                      type: qtn.metadata.type,
                      iconURL: qtn.metadata.iconURL,
                    },
                  ],
                }))
              )
              .flat();

            let questions = deduplicate(rawQuestions);

            if (!opt.withExcludedQuestions) {
              questions = questions.filter(
                (q) => !dataset.excludedQuestions?.includes(q.code)
              );
            }

            const tables = questionnaires.map((qtn) => qtn.tables).flat();

            let currencySymbol = '';
            const currencies = await currencyService.ListCurrencies();
            if (!currencies.error) {
              currencySymbol =
                currencies.find(
                  ({ identifier }) =>
                    identifier === dataset.metadata.company.currency
                )?.symbol ?? '';
            }

            let includedDatasets: Dataset[] = [];
            if (opt.withIncludedDatasets) {
              includedDatasets = (
                await Promise.all(
                  dataset.metadata.otherDatasets.map(async (datasetId) => {
                    try {
                      const includedDataset = await getDataset(datasetId);
                      return includedDataset;
                    } catch (err) {
                      console.error(err);
                    }

                    return null;
                  })
                )
              ).filter((ds) => ds !== null) as Dataset[];
            }

            resolve({
              metadata: questionnaires.map(({ metadata }) => metadata),
              dataset,
              questions,
              tables,
              currencySymbol,
              includedDatasets,
            });
          })
          .catch(reject);
      });
    },
    [getDataset]
  );

  const createDataset = useCallback((data: CreateDatasetParams) => {
    return new Promise<DatasetMetadata>((resolve, reject) => {
      datasetService.Create(data).then((response) => {
        if (response?.error) reject(response.error);
        else {
          const { metadata } = response.data;
          setDatasets((state) => [metadata, ...state]);
          resolve(metadata);
        }
      });
    });
  }, []);

  const cloneDataset = useCallback(
    async (params: CloneDatasetParams) => {
      const oldQuestionnaireVersions = await Promise.all(
        params.questionnaireIds.map((id) => questionnaireService.Get(id))
      );
      const newQuestionnaireVersionIds = await getLatestVersions(
        params?.questionnaireIds ?? [],
        params.metadata.language
      );
      const newQuestionnaireVersions = await Promise.all(
        newQuestionnaireVersionIds.map((id) => questionnaireService.Get(id))
      );

      const updatedDataset = updateQuestionnaires(
        {
          metadata: params.metadata as DatasetMetadata,
          answers: params.answers,
          questionnaireIds: newQuestionnaireVersionIds,
          excludedQuestions: params.excludedQuestions,
          approvals: [],
          verifications: [],
        },
        oldQuestionnaireVersions.map((oldVersion, index) => ({
          oldVersion,
          newVersion: newQuestionnaireVersions[index],
        }))
      );

      const response = await datasetService.Clone({
        metadata: {
          ...params.metadata,
          status: updatedDataset.metadata.status,
        },
        answers: updatedDataset.answers ?? [],
        questionnaireIds: newQuestionnaireVersionIds,
        excludedQuestions: params.excludedQuestions,
      });
      if (response.error) {
        throw response.error;
      }

      const { metadata } = response.data;
      setDatasets((state) => [metadata, ...state]);
      return metadata;
    },
    [getLatestVersions]
  );

  const saveDataset = useCallback((id, data: Dataset) => {
    data.metadata.parentId = data.metadata.revisionId;
    return new Promise<Dataset>((resolve, reject) => {
      datasetService.Update(id, data).then((response) => {
        if (response.error) reject(response.error);
        else {
          const { metadata } = response.data;
          setDatasets((state) =>
            state.map((dataset) => {
              if (dataset.id === metadata.id) return metadata;
              return dataset;
            })
          );
          resolve(response.data);
        }
      });
    });
  }, []);

  const updateDatasetQuestionnaires = useCallback((id: string) => {
    return new Promise<Dataset>((resolve, reject) => {
      datasetService.UpdateQuestionnaires(id).then((response) => {
        if (response.error) reject(response.error);
        else {
          const { metadata } = response.data;
          setDatasets((state) =>
            state.map((dataset) => {
              if (dataset.id === metadata.id) return metadata;
              return dataset;
            })
          );
          resolve(response.data);
        }
      });
    });
  }, []);

  // Removes datasets from the current array if they no longer fit the selected
  // filter.
  const applyCurrentFilter = useCallback(
    (dataset: DatasetMetadata): boolean => {
      if (filter === 'archived' && !dataset.archived) {
        return false;
      }
      if (
        (filter === 'active' || filter === 'active-approved') &&
        dataset.archived
      ) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const setArchived = useCallback(
    (id, archive) => {
      return new Promise<Dataset>((resolve, reject) => {
        const handleResponse = (response: IResponse<DatasetResponse>) => {
          if (response.error) reject(response.error);
          else {
            const { metadata } = response.data;
            setDatasets((state) =>
              state
                .map((dataset) => {
                  if (dataset.id === metadata.id) return metadata;
                  return dataset;
                })
                .filter(applyCurrentFilter)
            );
            resolve(response.data);
          }
        };

        if (archive) datasetService.Archive(id).then(handleResponse);
        else datasetService.Unarchive(id).then(handleResponse);
      });
    },
    [applyCurrentFilter]
  );

  const archiveDataset = useCallback(
    (id) => setArchived(id, true),
    [setArchived]
  );

  const unarchiveDataset = useCallback(
    (id) => setArchived(id, false),
    [setArchived]
  );

  const deleteDataset = useCallback((id) => {
    return new Promise<DeleteDatasetResponse>((resolve, reject) => {
      datasetService.DeleteDataset(id).then((response) => {
        if (response.error) reject(response.error);
        else {
          if (response.success) {
            setDatasets((state) =>
              state.filter((dataset) => dataset.id !== id)
            );
          }
          resolve(response);
        }
      });
    });
  }, []);

  const requestVerification = useCallback(
    (id) =>
      getDataset(id).then((response) =>
        saveDataset(id, {
          ...response,
          metadata: {
            ...response.metadata,
            verificationRequestDate: new Date().toISOString(),
            verificationStatus: 'requested' as DatasetVerificationStatus,
          },
        })
      ),
    [getDataset, saveDataset]
  );

  const clearState = useCallback(() => {
    setDatasets([]);
  }, []);

  return (
    <DatasetContext.Provider
      value={{
        datasets,
        fetchDatasets,
        createDataset,
        cloneDataset,
        getDataset,
        getQuestionnaire,
        saveDataset,
        updateDatasetQuestionnaires,
        archiveDataset,
        unarchiveDataset,
        deleteDataset,
        requestVerification,
        clearState,
      }}
    >
      {children}
    </DatasetContext.Provider>
  );
};

export function useDataset(): DatasetContextData {
  const context = useContext(DatasetContext);
  if (!context) {
    throw new Error('useDataset must be used within a DatasetProvider');
  }
  return context;
}
