import Api from '../api';

import {
  ImportParams,
  ImportResult,
  ListImportLogsParams,
  LogEntry,
} from './types';

class ImportService extends Api {
  config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  async ImportFile(params: ImportParams) {
    const { file, service, language, analyticsType } = params;

    const formData = new FormData();
    formData.append('file', file);

    let endpoint = `${service}/import`;

    if (service === 'analytics') {
      if (!analyticsType) {
        throw Error('Analytics import service requires type');
      }
      endpoint += `/${analyticsType}`;
    }

    if (language) {
      endpoint += `/${language}`;
    }

    return this.doRequest<ImportResult>({
      endpoint,
      method: 'POST',
      config: this.config,
      data: formData,
    });
  }

  async ListImportLogs(data: ListImportLogsParams) {
    return this.doRequest<LogEntry[]>({
      endpoint: `/imports?${data.type ? `type=${data.type}` : ''}`,
      data,
      method: 'GET',
    });
  }
}

export const importService = new ImportService();
