import React, { createContext, useCallback, useContext } from 'react';

import { S3 } from 'aws-sdk';

import { datasetService } from '../services';
import { Aws } from '../utils/aws';

import { useAuth } from './auth';

interface VerificationContextData {
  upload: (
    companyId: string,
    datasetId: string,
    file: File
  ) => Promise<S3.PutObjectOutput>;
  download: (companyId: string, datasetId: string) => Promise<File | null>;
  head: (companyId: string, datasetId: string) => Promise<S3.HeadObjectOutput>;
  deleteFile: (companyId: string, datasetId: string) => Promise<void>;
  downloadCompanyFile: (datasetId: string, file: string) => Promise<File>;
}

const VerificationContext = createContext<VerificationContextData>(
  {} as VerificationContextData
);

export const VerificationProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const upload = useCallback(
    async (companyId, datasetId, file) => {
      if (user === null) throw new Error('User is undefined');
      const key = `${companyId}/verification/${datasetId}/report.pdf`;

      return new Promise<S3.PutObjectOutput>((resolve, reject) => {
        Aws.uploadCompanyFile(file, key).then(resolve).catch(reject);
      });
    },
    [user]
  );

  const downloadCompanyFile = useCallback(
    async (datasetId, file): Promise<File> => {
      const url = await datasetService.GetFilePresignedUrl(datasetId, file);
      if (url.error) {
        throw url.error;
      }

      const response = await fetch(url);
      const blob = await response.blob();
      return new File([blob], file);
    },
    []
  );

  const head = useCallback(
    async (companyId, datasetId) => {
      if (user === null) throw new Error('User is undefined');

      return Aws.headCompanyFile(
        `${companyId}/verification/${datasetId}/report.pdf`
      );
    },
    [user]
  );

  const download = useCallback(
    async (companyId, datasetId) => {
      if (user === null) throw new Error('User is undefined');

      const response = await Aws.getCompanyFile(
        `${companyId}/verification/${datasetId}/report.pdf`
      );
      if (response === null) {
        return null;
      }
      return new File(
        [Buffer.from((response.Body as Uint8Array).buffer)],
        'report.pdf',
        { type: 'application/pdf' }
      );
    },
    [user]
  );

  const deleteFile = useCallback(
    async (companyId, datasetId) => {
      if (user === null) throw new Error('User is undefined');

      await Aws.deleteCompanyFile(
        `${companyId}/verification/${datasetId}/report.pdf`
      );
    },
    [user]
  );

  return (
    <VerificationContext.Provider
      value={{
        upload,
        download,
        head,
        deleteFile,
        downloadCompanyFile,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
};

export function useVerification(): VerificationContextData {
  const context = useContext(VerificationContext);
  if (!context) {
    throw new Error(
      'useVerification must be used within a VerificationProvider'
    );
  }
  return context;
}
