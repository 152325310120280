export async function withRetries<T>(
  p: () => Promise<T>,
  retries = 10, // total number of retries
  delay = 200 // delay between retries, in milliseconds
): Promise<T> {
  let error;

  /* eslint-disable no-await-in-loop */
  for (let i = 0; i < retries; i += 1) {
    try {
      const response = await p();
      return Promise.resolve(response);
    } catch (e) {
      error = e;
    }

    console.info('retries', p, i + 1);
    await new Promise((r) => setTimeout(r, delay));
  }
  /* eslint-enable no-await-in-loop */

  return Promise.reject(error);
}
