export { arrayToObjectBy } from 'common-utils';

// Returns a nested property of an object at the given path.
export const resolvePath = (
  obj: Record<string, unknown>,
  path: string
): unknown => {
  let properties = path.replace(/\[(\w+)\]/g, '.$1');
  properties = properties.replace(/^\./, '');
  const a = properties.split('.');
  let o = obj as unknown;
  for (let i = 0, n = a.length; i < n; i += 1) {
    const k = a[i];
    if (o && typeof o === 'object' && k in o) {
      o = o[k as keyof typeof o];
    } else {
      return undefined;
    }
  }
  return o;
};

export const deepAssign = (
  obj: Record<string, unknown>,
  path: string[],
  value: unknown
): Record<string, unknown> => {
  const key = path[0];
  if (path.length > 1) {
    if (!obj[key]) obj[key] = {};
    deepAssign(obj[key] as Record<string, unknown>, path.slice(1), value);
  } else {
    obj[key] = value;
  }
  return obj;
};
