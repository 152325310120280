import { PlanTiers, calculateTotalValue, getCouponValue } from 'common-utils';

import { CompanySize } from '../services/stripe/types';

export { getCouponValue, calculateTotalValue, PlanTiers };

// Maps company size codes to the `size` metadata tag in Stripe prices
export const CompanySizes: Record<string, CompanySize> = Object.freeze({
  MI: 'micro',
  SM: 'small',
  MD: 'medium',
  LG: 'large',
});

export const isCardExpired = (expYear: number, expMonth: number): boolean => {
  const now = new Date(Date.now());
  return (
    expYear < now.getUTCFullYear() ||
    (expYear === now.getUTCFullYear() && expMonth < now.getMonth() + 1)
  );
};
