import styled, { css, keyframes } from 'styled-components';

import { Close } from '../../assets/icons';
import { ToastType } from '../../providers/types';

interface ContainerProps {
  type: ToastType;
  index: number;
  timeout: number;
  animationTime: number;
  $exit: boolean;
}

const toastHeight = 50;
const toastWidth = 320;

const toastStyle = {
  info: css`
    background: ${({ theme }) => theme.colors.toast.info};
  `,
  success: css`
    background: ${({ theme }) => theme.colors.toast.success};
  `,
  warning: css`
    background: ${({ theme }) => theme.colors.toast.warning};
  `,
  error: css`
    background: ${({ theme }) => theme.colors.toast.error};
  `,
};

const appearFromRight = keyframes`
  from {
    transform: translateX(calc(100% + 30px));
  }
  to {
    transform: translateX(0);
  }
`;

const exitToRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(100% + 30px));
  }
`;

const animateWidth = keyframes`
  from {
    width: calc(${toastWidth}px - 2em);
  }
  to {
    width: 0;
  }
`;

export const TimeoutBar = styled.div`
  height: 4px;
  margin: 2px 1em;
  margin-left: auto;
  border-radius: 4px;
  background-color: #fff;
`;

export const Container = styled.div<ContainerProps>`
  width: ${toastWidth}px;
  min-width: ${toastWidth}px;
  min-height: ${toastHeight}px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px #0000001a;

  width: ${toastWidth}px;
  height: ${toastHeight}px;
  overflow: hidden;

  position: fixed;
  right: 30px;
  top: ${({ index }) => (index === 0 ? 30 : index * (toastHeight + 8) + 30)}px;

  visibility: ${({ $exit }) => $exit && 'hidden'};
  transition-property: visibility, top;
  transition-delay: ${({ animationTime }) => animationTime}ms, 0s;
  transition-duration: 0s, ${({ animationTime }) => animationTime}ms;

  ${({ type }) => toastStyle[type]}

  ${({ animationTime }) => css`
    animation: ${appearFromRight} ${animationTime}ms ease-out;
  `}

  ${({ $exit, animationTime }) =>
    $exit &&
    css`
      animation: ${exitToRight} ${animationTime}ms ease-out;
    `}

  > ${TimeoutBar} {
    width: ${({ $exit }) => $exit && '0'};

    ${({ timeout, animationTime }) => css`
      animation: ${animateWidth} ${timeout}ms linear ${animationTime}ms;
    `}
  }

  &:hover {
    width: fit-content;

    > ${TimeoutBar} {
      animation-play-state: paused;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 1em;
  padding-bottom: calc(1em - 8px);
`;

export const IconContainer = styled.div`
  width: 16px;
  height: 16px;
`;

export const Message = styled.div`
  margin: 0 0.6em;
  max-height: 19px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RemoveToastButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  width: 1.2em;
  height: 1.2em;
  background: none;
  border: none;
  border-radius: 2px;
`;

export const RemoveToastButtonIcon = styled(Close)`
  display: block;
  margin: auto;
  width: 10px;
  height: 10px;

  > path {
    fill: #fff;
  }
`;
