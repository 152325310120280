import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Mutex } from 'async-mutex';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { datasetService } from '../services';
import {
  CompanySize,
  CompanyType,
  SustainabilityDimension as Dimension,
  Identifier,
  Industry,
  Sector,
  DisclosureTopic as Topic,
} from '../services/datasets/types';

type SASBDatatype =
  | Sector
  | Industry
  | Dimension
  | Topic
  | CompanyType
  | CompanySize;

type SASBGet<K extends SASBDatatype> = (id: Identifier) => Promise<K>;
type SASBList<K extends SASBDatatype> = (lang: string) => Promise<K[]>;

type Service =
  | 'sectors'
  | 'industries'
  | 'dimensions'
  | 'topics'
  | 'companyTypes'
  | 'companySizes';

interface SASBContextData {
  getSector: SASBGet<Sector>;
  listSectors: SASBList<Sector>;
  getIndustry: SASBGet<Industry>;
  listIndustries: SASBList<Industry>;
  getDimension: SASBGet<Dimension>;
  listDimensions: SASBList<Dimension>;
  getTopic: SASBGet<Topic>;
  listTopics: SASBList<Topic>;
  getCompanyType: SASBGet<CompanyType>;
  listCompanyTypes: SASBList<CompanyType>;
  getCompanySize: SASBGet<CompanySize>;
  listCompanySizes: SASBList<CompanySize>;

  subscribe: () => void;
  unsubscribe: () => void;
}

// Map from language to translations.
// Access to `dataMap` is synchronized.
const dataMap: Record<
  string,
  {
    sectors: Sector[];
    industries: Industry[];
    dimensions: Dimension[];
    topics: Topic[];
    companyTypes: CompanyType[];
    companySizes: CompanySize[];
  }
> = {};
const mutex = new Mutex();

const SASBContext = createContext<SASBContextData>({} as SASBContextData);

export const SASBProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation();
  const [shouldFetch, setShouldFetch] = useState(false);

  const subscribe = useCallback(() => {
    setShouldFetch(true);
  }, []);

  const unsubscribe = useCallback(() => {
    setShouldFetch(false);
  }, []);

  const fetchDatatype = useCallback(
    (lang: string, service: Service): Promise<SASBDatatype[]> => {
      return new Promise<SASBDatatype[]>((resolve, reject) => {
        mutex
          .runExclusive(async () => {
            const data = dataMap[lang]?.[service];

            // If the translations are cached, return them
            if (data) {
              return data;
            }

            const res = await (async function f() {
              switch (service) {
                case 'sectors':
                  return datasetService.ListSectors(lang);
                case 'industries':
                  return datasetService.ListIndustries(lang);
                case 'dimensions':
                  return datasetService.ListSustainabilityDimensions(lang);
                case 'topics':
                  return datasetService.ListDisclosureTopics(lang);
                case 'companyTypes':
                  return datasetService.ListCompanyTypes(lang);
                case 'companySizes':
                  return datasetService.ListCompanySizes(lang);
                default:
                  return [];
              }
            })();

            // Get the translations for the language and add them to the map
            // const res = await services[service](lang);
            if (!res.error && res.length) {
              _.set(dataMap, `${lang}.${service}`, res);
              return res;
            }

            // Return 'en' translations as default
            const enData = dataMap.en?.[service];
            if (enData) {
              return enData;
            }

            return Promise.reject(
              Error(`failed to retrieve data for: ${service} ${lang}`)
            );
          })
          .then((result) => resolve(result))
          .catch(reject);
      });
    },
    []
  );

  const getDatatype = useCallback(
    (id: Identifier, service: Service): Promise<SASBDatatype> => {
      return new Promise<SASBDatatype>((resolve, reject) => {
        fetchDatatype(id.language, service).then((result) => {
          const value = result.find(({ code }) => code === id.code);
          if (value) {
            resolve(value);
          } else {
            reject(
              Error(
                `failed to retrieve data for ${service} ${id.code} ${id.language}`
              )
            );
          }
        });
      });
    },
    [fetchDatatype]
  );

  const listSectors = useCallback(
    (lang: string) => {
      return fetchDatatype(lang, 'sectors') as Promise<Sector[]>;
    },
    [fetchDatatype]
  );

  const getSector = useCallback(
    (id: Identifier) => {
      return getDatatype(id, 'sectors') as Promise<Sector>;
    },
    [getDatatype]
  );

  const listIndustries = useCallback(
    (lang: string) => {
      return fetchDatatype(lang, 'industries') as Promise<Industry[]>;
    },
    [fetchDatatype]
  );

  const getIndustry = useCallback(
    (id: Identifier) => {
      return getDatatype(id, 'industries') as Promise<Industry>;
    },
    [getDatatype]
  );

  const listDimensions = useCallback(
    (lang: string) => {
      return fetchDatatype(lang, 'dimensions') as Promise<Dimension[]>;
    },
    [fetchDatatype]
  );

  const getDimension = useCallback(
    (id: Identifier) => {
      return getDatatype(id, 'dimensions');
    },
    [getDatatype]
  );

  const listTopics = useCallback(
    (lang: string) => {
      return fetchDatatype(lang, 'topics') as Promise<Topic[]>;
    },
    [fetchDatatype]
  );

  const getTopic = useCallback(
    (id: Identifier) => {
      return getDatatype(id, 'topics') as Promise<Topic>;
    },
    [getDatatype]
  );

  const listCompanyTypes = useCallback(
    (lang: string) => {
      return fetchDatatype(lang, 'companyTypes') as Promise<CompanyType[]>;
    },
    [fetchDatatype]
  );

  const getCompanyType = useCallback(
    (id: Identifier) => {
      return getDatatype(id, 'companyTypes') as Promise<CompanyType>;
    },
    [getDatatype]
  );

  const listCompanySizes = useCallback(
    async (lang: string) => {
      const result = await fetchDatatype(lang, 'companySizes');

      const sizes = ['LG', 'MD', 'SM', 'MI'];

      result.sort((a, b) => {
        const va = sizes.indexOf(a.code);
        const vb = sizes.indexOf(b.code);
        return va - vb;
      });

      return result;
    },
    [fetchDatatype]
  );

  const getCompanySize = useCallback(
    (id: Identifier) => {
      return getDatatype(id, 'companySizes');
    },
    [getDatatype]
  );

  // Get all translations for the current language
  useEffect(() => {
    if (shouldFetch) {
      const lang = i18n.language;
      listSectors(lang).catch(console.error);
      listIndustries(lang).catch(console.error);
      listDimensions(lang).catch(console.error);
      listTopics(lang).catch(console.error);
      listCompanyTypes(lang).catch(console.error);
      listCompanySizes(lang).catch(console.error);
    }
  }, [
    listCompanySizes,
    listCompanyTypes,
    listDimensions,
    listIndustries,
    listSectors,
    listTopics,
    i18n.language,
    shouldFetch,
  ]);

  return (
    <SASBContext.Provider
      value={{
        listSectors,
        getSector,
        listIndustries,
        getIndustry,
        listDimensions,
        getDimension,
        listTopics,
        getTopic,
        listCompanyTypes,
        getCompanyType,
        listCompanySizes,
        getCompanySize,

        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </SASBContext.Provider>
  );
};

export function useSASB(): SASBContextData {
  const context = useContext(SASBContext);
  if (!context) {
    throw new Error('useSASB must be used within a SASBProvider');
  }
  return context;
}
