import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Index } from 'flexsearch';
import { useTranslation } from 'react-i18next';

import { glossaryService } from '../services';
import { deepAssign } from '../utils/object';

interface ResourcesContextData {
  resources?: Record<string, Record<string, { info: string; link: string }>>;
  index?: Index;
  indexTable: string[];
  subscribe: () => void;
  unsubscribe: () => void;
}

interface State {
  indices: Record<string, Index>;
  currentIndex: Index;
  data?: Record<string, Record<string, { info: string; link: string }>>;
}

let indexTable: string[] = [];

const populateIndex = (
  prefix: string,
  current: Record<string, unknown>,
  index: Index
) => {
  Object.keys(current).forEach((key) => {
    const idx = `${prefix}${key}`;
    indexTable.push(idx);
    index.addAsync(indexTable.length - 1, key);

    if (typeof current[key] === 'string') {
      indexTable.push(`${idx}{.}child`);
      index.addAsync(indexTable.length - 1, current[key] as string);
    } else {
      populateIndex(
        `${idx}{.}`,
        current[key] as Record<string, unknown>,
        index
      );
    }
  });
};

const ResourcesContext = createContext<ResourcesContextData>(
  {} as ResourcesContextData
);

export const ResourcesProvider: React.FC = ({ children }) => {
  const { i18n } = useTranslation();

  const [state, setState] = useState<State>();
  const [shouldFetch, setShouldFetch] = useState(false);

  const subscribe = useCallback(async () => {
    setShouldFetch(true);
  }, []);

  const unsubscribe = useCallback(async () => {
    setShouldFetch(false);
  }, []);

  const fetchResources = useCallback(async (lang: string) => {
    const resources = await glossaryService.ListResources(lang);

    if (resources && !resources?.error) {
      const Resources = {};

      resources.forEach((def) => {
        deepAssign(Resources, [def.code, def.title, 'info'], def.info);
        deepAssign(Resources, [def.code, def.title, 'link'], def.link);
      });

      setState((state) => {
        const indices = state?.indices || {};
        let index = indices?.[lang];
        if (!index) {
          index = new Index({ tokenize: 'forward' });
          deepAssign(indices, ['indices', lang], index);
        }

        indexTable = [];
        populateIndex('', Resources, index);
        return {
          indices,
          currentIndex: index,
          data: Resources,
        };
      });
    }
  }, []);

  useEffect(() => {
    if (shouldFetch) fetchResources(i18n.language);
  }, [shouldFetch, i18n.language, fetchResources]);

  return (
    <ResourcesContext.Provider
      value={{
        resources: state?.data,
        index: state?.currentIndex,
        indexTable,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </ResourcesContext.Provider>
  );
};

export function useResources(): ResourcesContextData {
  const context = useContext(ResourcesContext);
  if (!context) {
    throw new Error('useResources must be used within a ResourcesProvider');
  }
  return context;
}
