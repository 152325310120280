import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Mutex } from 'async-mutex';
import _ from 'lodash';

import { userService } from '../services';
import { UpdateUserParams, User } from '../services/users/types';
import { Aws } from '../utils/aws';

import { useAuth } from './auth';
import { User as AuthUser } from './types';

interface UserContextData {
  users?: User[];
  fetchUsers: (fromInfiniteScroll?: boolean) => Promise<User[]>;
  verifiers?: User[];
  fetchVerifiers: (fromInfiniteScroll?: boolean) => Promise<User[]>;
  getUser: (id: string) => Promise<User>;
  getUserAvatar: (path: string) => Promise<File | null>;
  inviteUser: (user: {
    email: string;
    role: string;
    companyName?: string;
    resend?: boolean;
  }) => Promise<User>;
  deleteUser: (id: string) => Promise<void>;
  updateUser: (id: string, data: UpdateUserParams) => Promise<void>;
  clearState: () => void;
}

interface UsersState {
  users: User[];
  paginationToken?: string;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

// Cache the results of `getUser`.
const users: User[] = [];
const usersMutex = new Mutex();

// Cache user avatars.
// `avatars` maps the s3 path to the image file.
const avatars: Record<string, File | null> = {};
const avatarsMutex = new Mutex();

export const UserProvider: React.FC = ({ children }) => {
  const { user } = useAuth();

  const [usersState, setUsersState] = useState<UsersState>();
  const [verifiers, setVerifiers] = useState<UsersState>();

  const fetchUsers = useCallback(
    (fromInfiniteScroll?: boolean) => {
      return new Promise<User[]>((resolve, reject) => {
        userService
          .ListUsers({
            limit: 50,
            paginationToken:
              fromInfiniteScroll && usersState?.paginationToken
                ? usersState.paginationToken
                : undefined,
            companyId: user?.company.id,
          })
          .then((result) => {
            if (result.error) reject(result.error);
            else {
              const { users, paginationToken } = result;

              if (fromInfiniteScroll) {
                setUsersState((state) => ({
                  paginationToken,
                  users: _.unionBy(state?.users, users, 'id'),
                }));
              } else {
                setUsersState({
                  paginationToken,
                  users,
                });
              }
              resolve(users);
            }
          });
      });
    },
    [usersState?.paginationToken, user?.company.id]
  );

  const fetchVerifiers = useCallback(
    (fromInfiniteScroll?: boolean) => {
      return new Promise<User[]>((resolve, reject) => {
        userService
          .ListUsers({
            limit: 50,
            paginationToken:
              fromInfiniteScroll && verifiers?.paginationToken
                ? verifiers.paginationToken
                : undefined,
          })
          .then((result) => {
            if (result.error) reject(result.error);
            else {
              const { paginationToken } = result;
              const { users } = result;

              if (fromInfiniteScroll) {
                setVerifiers((state) => ({
                  paginationToken,
                  users: _.unionBy(state?.users, users, 'id'),
                }));
              } else {
                setVerifiers({
                  paginationToken,
                  users,
                });
              }
              resolve(users);
            }
          });
      });
    },
    [verifiers?.paginationToken]
  );
  const inviteUser = useCallback((user) => {
    return new Promise<User>((resolve, reject) => {
      userService.InviteUser(user).then((result) => {
        if (result?.error) {
          reject(result.error);
        } else {
          if (!user?.resend) {
            if (result.role === 'verifier') {
              setVerifiers((state) => ({
                paginationToken: state?.paginationToken ?? '',
                users: state?.users ? [...state.users, result] : [result],
              }));
            } else {
              setUsersState((state) => ({
                paginationToken: state?.paginationToken ?? '',
                users: state?.users ? [...state.users, result] : [result],
              }));
            }
          }
          resolve(result);
        }
      });
    });
  }, []);

  const deleteUser = useCallback((id) => {
    return new Promise<void>((resolve, reject) => {
      userService.DeleteUser(id).then((result) => {
        if (result?.error) {
          reject(result.error);
        } else {
          setUsersState((state) => ({
            paginationToken: state?.paginationToken ?? '',
            users: state?.users.filter((user) => user.id !== id) ?? [],
          }));
          resolve();
        }
      });
    });
  }, []);

  const updateUser = useCallback((id, data) => {
    return new Promise<void>((resolve, reject) => {
      userService.UpdateUser(id, data).then((result) => {
        if (result?.error) {
          reject(result.error);
        } else {
          setUsersState((state) => ({
            paginationToken: state?.paginationToken ?? '',
            users:
              state?.users.map((user) => {
                if (user.id === result.id) return result;
                return user;
              }) ?? [],
          }));
          resolve();
        }
      });
    });
  }, []);

  const getUser = useCallback(
    async (id) => {
      // If the user is in the component's state, return it
      let user: User | undefined;
      if (usersState?.users.length) {
        user = _.find(usersState.users, { id });
        if (user) return user;
      }

      // Otherwise, check the `users` cache
      return new Promise<User>((resolve, reject) => {
        usersMutex
          .runExclusive(async () => {
            if (users.length) {
              user = _.find(users, { id });
              if (user) return user;
            }

            const response = await userService.GetUser(id);
            if (response?.error) {
              throw response.error;
            } else {
              users.push(response);
              return response;
            }
          })
          .then(resolve)
          .catch(reject);
      });
    },
    [usersState?.users]
  );

  const getUserAvatar = useCallback(async (path: string) => {
    return new Promise<File | null>((resolve, reject) => {
      avatarsMutex
        .runExclusive(async () => {
          if (avatars[path] !== undefined) {
            return avatars[path];
          }

          const response = await Aws.getUserFile(path);
          if (response === null) {
            _.set(avatars, path, null);
            return null;
          }
          const avatar = new File(
            [Buffer.from((response.Body as Uint8Array).buffer)],
            'avatar'
          );

          _.set(avatars, path, avatar);
          return avatar;
        })
        .then(resolve)
        .catch(reject);
    });
  }, []);

  const updateStateWithAuthUserChanges = useCallback(
    (
      user: AuthUser,
      setState: React.Dispatch<React.SetStateAction<UsersState | undefined>>
    ) => {
      setState((state) => {
        if (state) {
          return {
            paginationToken: state?.paginationToken ?? '',
            users: state.users.map((userInState) => {
              if (userInState.id === user?.id) {
                return {
                  ...user,
                  status: userInState.status,
                  companyId: user.company.id,
                };
              }
              return userInState;
            }),
          };
        }

        return state;
      });
    },
    []
  );

  // Listen for changes to the authenticated user to update the lists
  useEffect(() => {
    if (user) {
      updateStateWithAuthUserChanges(user, setUsersState);
      updateStateWithAuthUserChanges(user, setVerifiers);
    }
  }, [user, updateStateWithAuthUserChanges]);

  const clearState = useCallback(() => {
    setUsersState(undefined);
    setVerifiers(undefined);
  }, []);

  return (
    <UserContext.Provider
      value={{
        users: usersState?.users,
        fetchUsers,
        verifiers: verifiers?.users,
        fetchVerifiers,
        getUser,
        getUserAvatar,
        inviteUser,
        deleteUser,
        updateUser,
        clearState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUsers(): UserContextData {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUsers must be used within a UserProvider');
  }
  return context;
}
