import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import useWindowDimensions from '../hooks/useWindowDimensions';

interface NavbarContextData {
  width: string;
  widthLimit: {
    max: string;
    min: string;
    collapsed: string;
  };
  isCollapsed: boolean;
  toggleIsCollapsed: () => void;
}

const widthLimit = { max: 250, min: 210, collapsed: 59 };

const NavbarContext = createContext<NavbarContextData>({} as NavbarContextData);

const stateStorageId = '@ST:navbar';

export const NavbarProvider: React.FC = ({ children }) => {
  const [width, setWidth] = useState(widthLimit.max);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(() => {
    const state = localStorage.getItem(stateStorageId);
    if (state === null) return false;
    return JSON.parse(state) as boolean;
  });

  const { width: windowWidth } = useWindowDimensions();

  const toggleIsCollapsed = useCallback(() => {
    setIsCollapsed((state) => {
      localStorage.setItem(stateStorageId, JSON.stringify(!state));
      return !state;
    });
  }, []);

  useEffect(() => {
    setWidth(
      isCollapsed
        ? widthLimit.collapsed
        : Math.max(Math.min(windowWidth * 0.16, widthLimit.max), widthLimit.min)
    );
  }, [isCollapsed, windowWidth]);

  return (
    <NavbarContext.Provider
      value={{
        width: `${width}px`,
        widthLimit: {
          max: `${widthLimit.max}px`,
          min: `${widthLimit.min}px`,
          collapsed: `${widthLimit.collapsed}px`,
        },
        isCollapsed,
        toggleIsCollapsed,
      }}
    >
      {children}
    </NavbarContext.Provider>
  );
};

export function useNavbar(): NavbarContextData {
  const context = useContext(NavbarContext);
  if (!context) {
    throw new Error('useNavbar must be used within a NavbarProvider');
  }
  return context;
}
