import Api from '../api';

import {
  BillingDetails,
  GetInvoicesResponse,
  GetPlanResponse,
  Intent,
  Invoice,
  ListStripeProductsParams,
  PaymentMethod,
  StripeProduct,
  StripeTaxRate,
  SubscribeParams,
} from './types';

class StripeService extends Api {
  async ListStripeProducts(data: ListStripeProductsParams) {
    return this.doRequest<StripeProduct[]>({
      endpoint: '/companies/plans',
      data,
      method: 'GET',
    });
  }

  async ListTaxRates() {
    return this.doRequest<StripeTaxRate[]>({
      endpoint: '/stripe/taxRates',
    });
  }

  async ListPaymentMethods(companyId: string) {
    return this.doRequest<{ data: PaymentMethod[] }>({
      endpoint: `/companies/${companyId}/payment-method`,
    });
  }

  async GetIntentPaymentMethod(companyId: string, intentId: string) {
    return this.doRequest<{ id: string }>({
      endpoint: `/companies/${companyId}/payment-method/intent/${intentId}`,
    });
  }

  async GetActivePaymentMethod(companyId: string) {
    return this.doRequest<PaymentMethod>({
      endpoint: `/companies/${companyId}/payment-method/active`,
    });
  }

  async StartPaymentMethod(companyId: string) {
    return this.doRequest<Intent>({
      endpoint: `/companies/${companyId}/payment-method/start`,
      method: 'POST',
    });
  }

  async ConfirmPaymentIntent(companyId: string, paymentIntentId: string) {
    return this.doRequest<Intent>({
      endpoint: `/companies/${companyId}/payment-intent/${paymentIntentId}/confirm`,
      method: 'POST',
    });
  }

  async SetDefaultPaymentMethod(companyId: string, paymentMethodId: string) {
    return this.doRequest({
      endpoint: `/companies/${companyId}/payment-method/${paymentMethodId}/default`,
      method: 'PUT',
    });
  }

  async DeletePaymentMethod(companyId: string, paymentMethodId: string) {
    return this.doRequest({
      endpoint: `/companies/${companyId}/payment-method/${paymentMethodId}`,
      method: 'DELETE',
    });
  }

  async PreviewInvoice(companyId: string, priceId: string) {
    return this.doRequest<Invoice>({
      endpoint: `/companies/${companyId}/preview-invoice/${priceId}`,
    });
  }

  async BeginTrial(companyId: string, plan: string, currency: string) {
    return this.doRequest({
      endpoint: `companies/${companyId}/subscription/begin-trial`,
      data: { plan, currency },
      method: 'PUT',
    });
  }

  async SubscribeToPlan(
    companyId: string,
    priceId: string,
    data: SubscribeParams
  ) {
    return this.doRequest({
      endpoint: `/companies/${companyId}/subscribe/${priceId}`,
      data,
      method: 'POST',
    });
  }

  async ApplyCoupon(companyId: string, subscriptionId: string, coupon: string) {
    return this.doRequest({
      endpoint: `/companies/${companyId}/subscription/${subscriptionId}/apply-coupon`,
      data: { coupon },
      method: 'PUT',
    });
  }

  async GetInvoices(companyId: string) {
    return this.doRequest<GetInvoicesResponse>({
      endpoint: `/companies/${companyId}/invoices`,
    });
  }

  async GetPlan(companyId: string) {
    return this.doRequest<GetPlanResponse>({
      endpoint: `/companies/${companyId}/plan`,
    });
  }

  async GetBillingDetails(companyId: string) {
    return this.doRequest<BillingDetails>({
      endpoint: `/companies/${companyId}/billing-details`,
    });
  }

  async UpdateBillingDetails(companyId: string, data: BillingDetails) {
    return this.doRequest<BillingDetails>({
      endpoint: `/companies/${companyId}/billing-details`,
      data,
      method: 'PUT',
    });
  }
}

export const stripeService = new StripeService();
