import * as Sentry from '@sentry/react';
import axios, { AxiosInstance, ResponseType } from 'axios';

import { api } from '../config';
import { Aws } from '../utils/aws';
import { withRetries } from '../utils/retry';

export type IResponse<T> = T & {
  error?: Error;
};

interface Config {
  headers?: {
    Authorization?: string;
    'Content-Type'?: string;
  };
  params?: unknown;
  responseType?: ResponseType;
}

interface IRequest {
  endpoint: string;
  data?: unknown;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  config?: Config;
}

class Api {
  protected api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: api.baseURL,
    });
  }

  protected async doRequest<T>({
    endpoint,
    data,
    method,
    config: conf,
  }: IRequest): Promise<IResponse<T>> {
    let token = '';
    try {
      token = await withRetries(() => Aws.getIdToken());
    } catch (error) {
      const err = error as Error;
      console.error(err.message);

      window.dispatchEvent(new Event('logout'));
      return { error: err } as IResponse<T>;
    }

    const config: Config = {
      ...conf,
      headers: {
        ...conf?.headers,
        Authorization: `Bearer ${token}`,
      },
    };

    let m = method;
    if (!m) m = data ? 'POST' : 'GET';

    const response = await (function f(that) {
      switch (m) {
        case 'POST':
          return that.api.post(endpoint, data, config);
        case 'PUT':
          return that.api.put(endpoint, data, config);
        case 'DELETE':
          return that.api.delete(endpoint, config);
        default:
        case 'GET':
          if (data) config.params = data;
          return that.api.get(endpoint, config);
      }
    })(this)
      .then((res) => {
        console.info(m, endpoint, res.data);
        return res.data;
      })
      .catch((error) => {
        console.error(m, endpoint, error.response?.data);
        if (error.response?.data.code === 'Generic/MalformedRequest') {
          Sentry.withScope((scope) => {
            scope.addBreadcrumb({
              data: error.response.data,
            });
            Sentry.captureMessage('MalformedRequest');
          });
        }
        return {
          error: new Error(
            error.response?.data.error ||
              error.response?.data.message ||
              error.response ||
              'API Error'
          ),
        };
      });

    return response;
  }
}

export default Api;
