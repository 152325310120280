import * as AWS from 'aws-sdk';

import { aws } from '../../config';

class S3Class {
  private s3: AWS.S3 | null;

  constructor() {
    this.s3 = null;
  }

  public refresh(credentials: AWS.CognitoIdentityCredentials) {
    this.s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      region: aws.Region,
      credentials,
    });
  }

  public listObjects(bucket: string, prefix: string, delimiter: string) {
    return new Promise<AWS.S3.ListObjectsV2Output>((resolve, reject) => {
      if (this.s3 === null) reject(Error('S3 client is undefined'));
      else
        resolve(
          this.s3
            .listObjectsV2({
              Bucket: bucket,
              Prefix: prefix,
              Delimiter: delimiter,
            })
            .promise()
        );
    });
  }

  public uploadFile(
    bucket: string,
    file: File,
    path: string,
    metadata?: Record<string, string>
  ) {
    return new Promise<AWS.S3.PutObjectOutput>((resolve, reject) => {
      if (this.s3 === null) reject(Error('S3 client is undefined'));
      else
        resolve(
          this.s3
            .putObject({
              Bucket: bucket,
              Key: path,
              Body: file,
              ContentType: file.type,
              Metadata: metadata,
            })
            .promise()
        );
    });
  }

  public deleteFile(bucket: string, path: string) {
    return new Promise((resolve, reject) => {
      if (this.s3 === null) reject(Error('S3 client is undefined'));
      else
        resolve(
          this.s3
            .deleteObject({
              Bucket: bucket,
              Key: path,
            })
            .promise()
        );
    });
  }

  public getFile(bucket: string, path: string, versionId?: string) {
    return new Promise<AWS.S3.GetObjectOutput | null>((resolve, reject) => {
      if (this.s3 === null) reject(Error('S3 client is undefined'));
      else
        this.s3.getObject(
          {
            Bucket: bucket,
            Key: path,
            VersionId: versionId,
          },
          (error, data) => {
            if (error || !data?.Body) {
              resolve(null);
              return;
            }
            resolve(data);
          }
        );
    });
  }

  public headFile(bucket: string, path: string) {
    return new Promise<AWS.S3.HeadObjectOutput>((resolve, reject) => {
      if (this.s3 === null) reject(Error('S3 client is undefined'));
      else
        this.s3.headObject(
          {
            Bucket: bucket,
            Key: path,
          },
          (error, data) => {
            if (error) reject(error);
            else resolve(data);
          }
        );
    });
  }
}

export const S3 = new S3Class();
