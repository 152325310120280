import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { S3 } from 'aws-sdk';
import { Index } from 'flexsearch';
import { useTranslation } from 'react-i18next';

import { datasetService } from '../services';
import { Aws } from '../utils/aws';
import { formatDate } from '../utils/date';

import { useAuth } from './auth';
import { useUsers } from './users';

export type NodeList = S3.ObjectList;

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  avatarPath?: string;
}

export interface Node {
  id: string;
  type: string;
  version: string;
  name: {
    value: string;
    inDataset: boolean;
    approved: boolean;
  };
  date: string;
  owner?: string | User;
  actions: string;
}

interface DataRoomContextData {
  ls: (dir: string) => Promise<Node[]>;
  upload: (dir: string, file: File) => Promise<unknown>;
  rm: (key: string) => Promise<unknown>;
  getFile: (key: string, versionId?: string) => Promise<File | null>;
  headFile: (key: string) => Promise<S3.HeadObjectOutput>;
  index: Index;
  indexTable: Node[];
  subscribe: () => void;
  unsubscribe: () => void;
}

// index of file names to s3 objects
const indexTable: Node[] = [];
const index = new Index({ tokenize: 'forward' });

const DataRoomContext = createContext<DataRoomContextData>(
  {} as DataRoomContextData
);

export const DataRoomProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('data-room');
  const { getUser } = useUsers();
  const { user } = useAuth();

  const [shouldFetch, setShouldFetch] = useState(false);

  const subscribe = useCallback(async () => {
    setShouldFetch((state) => {
      if (state) return state;
      return true;
    });
  }, []);

  const unsubscribe = useCallback(async () => {
    setShouldFetch(false);
  }, []);

  const headFile = useCallback((key: string) => Aws.headCompanyFile(key), []);

  const ls = useCallback(
    async (dir: string): Promise<Node[]> => {
      if (user === null) throw new Error('User is undefined');
      const { id: companyId } = user.company;

      const result = await Aws.listCompanyDirectory(
        `${companyId}/DataRoom/${dir}`
      );
      const { Contents, CommonPrefixes } = result;

      if (Contents?.length || CommonPrefixes?.length) {
        const promises = await Promise.all(
          (Contents ?? []).map(async (obj) => {
            if (obj.Key && obj.Key !== result.Prefix) {
              const file = await headFile(obj.Key);
              return {
                ...obj,
                file,
              };
            }
            return null;
          })
        );

        let filesInDatasets = promises.length
          ? await datasetService.ListFilesInDatasets({
              prefix: result?.Prefix
                ? encodeURIComponent(result.Prefix)
                : undefined,
            })
          : {};
        if (filesInDatasets?.error) filesInDatasets = {};

        let newNodes: Node[] = (
          await Promise.all(
            promises.map(async (promise) => {
              if (promise !== null) {
                const userId = promise.file?.Metadata?.user_id;
                let fileuser;
                if (userId) {
                  try {
                    const res = await getUser(userId);
                    if (res) {
                      fileuser = {
                        ...res,
                        firstName: res?.name,
                        lastName: res?.familyName,
                        avatarPath: `${user.company.id}/${res.id}/profilepic`,
                      };
                    }
                  } catch (e) {
                    console.error(e);
                  }
                }

                const fileDatasets = filesInDatasets[promise.Key ?? ''];
                const inDataset = fileDatasets !== undefined;
                const approved = fileDatasets?.some(
                  ({ approvalDate }) => approvalDate !== null
                );

                return {
                  id: promise.Key,
                  type: promise.file?.ContentType,
                  version: promise.file.VersionId ?? null,
                  name: {
                    value: promise.Key?.split('/').pop(),
                    inDataset,
                    approved,
                  },
                  date: formatDate(
                    t,
                    promise.LastModified,
                    user?.company.dateFormat
                  ),
                  owner: fileuser ?? '-',
                  actions: approved ? 'disable-delete' : '',
                };
              }
              return null;
            })
          )
        ).filter((node) => node !== null) as Node[];

        if (CommonPrefixes?.length) {
          newNodes = (
            CommonPrefixes.map((prefix) => {
              if (prefix !== undefined) {
                return {
                  id: prefix.Prefix,
                  type: 'dir',
                  name: {
                    value: prefix.Prefix?.split('/').slice(-2, -1)[0],
                    inDataset: false,
                    approved: false,
                  },
                  date: '',
                  owner: '',
                  actions: 'dir',
                };
              }
              return null;
            }).filter((node) => node !== null) as Node[]
          ).concat(newNodes);
        }

        return newNodes;
      }
      return [];
    },
    [user, headFile, getUser, t]
  );

  const upload = useCallback(
    async (dir: string, file: File) => {
      if (user === null) throw new Error('User is undefined');
      const { id: companyId } = user.company;
      const metadata = { user_id: user.id };
      const Key = `${companyId}/DataRoom/${dir}`;

      return new Promise<S3.PutObjectOutput>((resolve, reject) => {
        Aws.uploadCompanyFile(file, Key, metadata)
          .then(async (data) => {
            const file = await headFile(Key);
            const name = Key.split('/').pop();

            if (name) {
              let filesInDatasets = await datasetService.ListFilesInDatasets({
                prefix: Key.replace(name, ''),
              });
              if (filesInDatasets?.error) filesInDatasets = {};
              const fileDatasets = filesInDatasets[Key ?? ''];
              const inDataset = fileDatasets !== undefined;
              const approved = fileDatasets?.some(
                ({ approvalDate }) => approvalDate !== null
              );

              indexTable.push({
                id: Key,
                type: file.ContentType ?? '',
                version: file.VersionId ?? 'null',
                name: {
                  value: name,
                  inDataset,
                  approved,
                },
                date: '-',
                owner: '-',
                actions: approved ? 'disable-delete' : '',
              });
              index.addAsync(indexTable.length - 1, name);

              resolve(data);
            }
          })
          .catch(reject);
      });
    },
    [user, headFile]
  );

  const rm = useCallback(async (key: string) => {
    const response = await datasetService.DeleteFile(encodeURIComponent(key));
    if (response.error) throw Error(response.error.message);
  }, []);

  const getFile = useCallback(async (key: string, versionId?: string) => {
    const response = await Aws.getCompanyFile(key, versionId);
    if (response === null) {
      return Promise.reject(Error(`file doesn't exist: ${key}`));
    }
    return new File([Buffer.from((response.Body as Uint8Array).buffer)], key);
  }, []);

  const populateIndex = useCallback(async () => {
    if (user === null) throw new Error('User is undefined');
    const { id: companyId } = user.company;
    const { Contents } = await Aws.listCompanyDirectory(
      `${companyId}/DataRoom/`,
      ''
    );

    let filesInDatasets = await datasetService.ListFilesInDatasets({
      prefix: '',
    });
    if (filesInDatasets?.error) filesInDatasets = {};

    Contents?.forEach(async (item) => {
      const { Key } = item;

      if (Key) {
        const name = Key.split('/').pop();
        if (name) {
          const file = await headFile(Key);

          const fileDatasets = filesInDatasets[Key ?? ''];
          const inDataset = fileDatasets !== undefined;
          const approved = fileDatasets?.some(
            ({ approvalDate }) => approvalDate !== null
          );

          indexTable.push({
            id: Key,
            type: file.ContentType ?? '',
            version: file.VersionId ?? 'null',
            name: {
              value: name,
              inDataset,
              approved,
            },
            date: '-',
            owner: '-',
            actions: approved ? 'disable-delete' : '',
          });
          index.addAsync(indexTable.length - 1, name);
        }
      }
    });
  }, [user, headFile]);

  useEffect(() => {
    if (shouldFetch && indexTable.length === 0 && user !== null) {
      populateIndex();
    }
  }, [shouldFetch, populateIndex, user]);

  return (
    <DataRoomContext.Provider
      value={{
        ls,
        upload,
        rm,
        headFile,
        getFile,
        index,
        indexTable,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </DataRoomContext.Provider>
  );
};

export function useDataRoom(): DataRoomContextData {
  const context = useContext(DataRoomContext);
  if (!context) {
    throw new Error('useDataRoom must be used within a DataRoomProvider');
  }
  return context;
}
