import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk';

import { aws } from '../../config';

import { S3 } from './s3';

interface ISignInResponse extends CognitoUser {
  forceChangePassword?: boolean;
}

const ExpiredSessionError = new Error('The session has expired');

class AwsClass {
  private userPool: CognitoUserPool;

  public completeNewPassword;

  constructor() {
    this.userPool = new CognitoUserPool(aws);
    this.completeNewPassword = AwsClass.CompleteNewPassword.bind(this);
    AWS.config.region = aws.Region;
  }

  private createCognitoUser(username: string) {
    return new CognitoUser({
      Username: username,
      Pool: this.userPool,
    });
  }

  public signUp(
    username: string,
    password: string,
    attributes: CognitoUserAttribute[]
  ) {
    return new Promise<CognitoUser>((resolve, reject) => {
      this.userPool.signUp(
        username,
        password,
        attributes,
        [],
        (error, result) => {
          if (error) reject(error);
          else {
            const cognitoUser = result?.user;
            if (cognitoUser) resolve(cognitoUser);
          }
        }
      );
    });
  }

  public confirmSignUp(username: string, code: string) {
    const cognitoUser = this.createCognitoUser(username);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (error, result) => {
        if (error) reject(error);
        else resolve(result);
      });
    });
  }

  public resendConfirmationCode(username: string) {
    const cognitoUser = this.createCognitoUser(username);

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((error, result) => {
        if (error) reject(error);
        else resolve(result);
      });
    });
  }

  private getAuthenticatedUser() {
    const cUser = this.userPool.getCurrentUser();
    if (cUser !== null) {
      cUser.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (error) console.error('cognito user error', error);
          else if (session) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const Logins: any = {};
            Logins[
              `cognito-idp.${aws.Region}.amazonaws.com/${aws.UserPoolId}`
            ] = session.getIdToken().getJwtToken();

            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: aws.IdentityPoolId,
              Logins,
            });

            S3.refresh(
              AWS.config.credentials as AWS.CognitoIdentityCredentials
            );
          }
        }
      );
    }
    return cUser;
  }

  public refreshUserSession(): Promise<ISignInResponse> {
    const cUser = this.userPool.getCurrentUser();
    if (cUser === null) {
      throw new Error('Failed to get user session');
    }

    return new Promise<ISignInResponse>((resolve, reject) => {
      cUser.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (error) reject(error);
          else if (session) {
            cUser.refreshSession(session.getRefreshToken(), (error, result) => {
              if (error) reject(error);
              else if (result) {
                AwsClass.establishUserSession(session, cUser, resolve, reject);
              }
            });
          }
        }
      );
    });
  }

  private static establishUserSession(
    session: CognitoUserSession,
    cognitoUser: CognitoUser,
    resolve: (data: ISignInResponse) => void,
    reject: (reason: unknown) => void
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const Logins: any = {};
    Logins[`cognito-idp.${aws.Region}.amazonaws.com/${aws.UserPoolId}`] =
      session.getIdToken().getJwtToken();

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: aws.IdentityPoolId,
      Logins,
    });

    S3.refresh(AWS.config.credentials as AWS.CognitoIdentityCredentials);

    (AWS.config.credentials as AWS.CognitoIdentityCredentials).clearCachedId();

    (AWS.config.credentials as AWS.CognitoIdentityCredentials).refresh(
      (error) => {
        if (error !== null) reject(error);
        else resolve(cognitoUser);
      }
    );
    return null;
  }

  public signIn(username: string, password: string): Promise<ISignInResponse> {
    const authData = { Username: username, Password: password };
    const authDetails = new AuthenticationDetails(authData);
    const cognitoUser = this.createCognitoUser(username);

    return new Promise<ISignInResponse>((resolve, reject) => {
      cognitoUser.authenticateUser(authDetails, {
        onSuccess: (result) => {
          AwsClass.establishUserSession(result, cognitoUser, resolve, reject);
        },
        onFailure: reject,
        newPasswordRequired: () => {
          (cognitoUser as ISignInResponse).forceChangePassword = true;
          resolve(cognitoUser);
        },
      });
    });
  }

  public static CompleteNewPassword(
    password: string,
    cognitoUser?: CognitoUser | null
  ): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      if (cognitoUser == null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.completeNewPasswordChallenge(
          password,
          {},
          {
            onSuccess: (session) =>
              AwsClass.establishUserSession(
                session,
                cognitoUser,
                resolve,
                reject
              ),
            onFailure: reject,
          }
        );
      }
    });
  }

  public signOut() {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = this.getAuthenticatedUser();

      if (cognitoUser == null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.signOut();
        resolve();
      }
    });
  }

  public forgotPassword(username: string) {
    const cognitoUser = this.createCognitoUser(username);

    return new Promise((resolve, reject) =>
      cognitoUser.forgotPassword({
        onSuccess: (data) => resolve(data),
        onFailure: reject,
      })
    );
  }

  public confirmPassword(username: string, code: string, password: string) {
    const cognitoUser = this.createCognitoUser(username);

    return new Promise<void>((resolve, reject) => {
      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => resolve(),
        onFailure: reject,
      });
    });
  }

  public sendEmailVerificationCode() {
    return new Promise<void>((resolve, reject) => {
      const cognitoUser = this.getAuthenticatedUser();

      if (cognitoUser == null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.getAttributeVerificationCode('email', {
          onSuccess: () => resolve(),
          onFailure: reject,
        });
      }
    });
  }

  public verifyEmail(code: string): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      const cognitoUser = this.getAuthenticatedUser();

      if (cognitoUser == null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.verifyAttribute('email', code, {
          onSuccess: () => resolve(cognitoUser),
          onFailure: reject,
        });
      }
    });
  }

  public updateAttributes(
    attributes: CognitoUserAttribute[]
  ): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      const cognitoUser = this.getAuthenticatedUser();

      if (cognitoUser === null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.updateAttributes(attributes, (error) => {
          if (error) reject(error);
          else resolve(cognitoUser);
        });
      }
    });
  }

  public changePassword(password: string, newPassword: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getAuthenticatedUser();

      if (cognitoUser === null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.changePassword(password, newPassword, (error, result) => {
          if (error) reject(error);
          else resolve(result);
        });
      }
    });
  }

  public deleteAccount() {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getAuthenticatedUser();

      if (cognitoUser === null || cognitoUser === undefined) {
        reject(ExpiredSessionError);
      } else {
        cognitoUser.deleteUser((error, result) => {
          if (error) reject(error);
          else {
            cognitoUser.signOut();
            resolve(result);
          }
        });
      }
    });
  }

  public getIdToken(): Promise<string> {
    const cognitoUser = this.getAuthenticatedUser();
    if (!cognitoUser) {
      throw ExpiredSessionError;
    }

    return new Promise<string>((resolve, reject) => {
      cognitoUser.getSession(
        (error: Error | null, session: CognitoUserSession | null) => {
          if (error) reject(error);
          else if (session) {
            const expiration = session.getIdToken().getExpiration();
            const currentTime = Math.round(+new Date() / 1000);
            const idToken: string = session.getIdToken().getJwtToken();

            if (expiration < currentTime) {
              cognitoUser.refreshSession(
                session.getRefreshToken(),
                (error, result) => {
                  if (error) reject(error);
                  else if (result) {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const Logins: any = {};
                    Logins[
                      `cognito-idp.${aws.Region}.amazonaws.com/${aws.UserPoolId}`
                    ] = session.getIdToken().getJwtToken();

                    AWS.config.credentials = new AWS.CognitoIdentityCredentials(
                      {
                        IdentityPoolId: aws.IdentityPoolId,
                        Logins,
                      }
                    );

                    S3.refresh(
                      AWS.config.credentials as AWS.CognitoIdentityCredentials
                    );

                    resolve(session.getIdToken().getJwtToken());
                  }
                }
              );
            } else resolve(idToken);
          }
        }
      );
    });
  }

  public listCompanyDirectory(path: string, delimiter?: string) {
    this.getAuthenticatedUser();
    return S3.listObjects(aws.CompanyBucket, path, delimiter ?? '/');
  }

  public uploadUserFile(file: File, path: string) {
    this.getAuthenticatedUser();
    return S3.uploadFile(aws.CognitoUserBucket, file, path);
  }

  public uploadCompanyFile(
    file: File,
    path: string,
    metadata?: Record<string, string>
  ) {
    this.getAuthenticatedUser();
    return S3.uploadFile(aws.CompanyBucket, file, path, metadata);
  }

  public deleteCompanyFile(path: string) {
    this.getAuthenticatedUser();
    return S3.deleteFile(aws.CompanyBucket, path);
  }

  public deleteUserFile(path: string) {
    this.getAuthenticatedUser();
    return S3.deleteFile(aws.CognitoUserBucket, path);
  }

  public getUserFile(path: string) {
    this.getAuthenticatedUser();
    return S3.getFile(aws.CognitoUserBucket, path);
  }

  public getCompanyFile(path: string, versionId?: string) {
    this.getAuthenticatedUser();
    return S3.getFile(aws.CompanyBucket, path, versionId);
  }

  public headCompanyFile(path: string) {
    this.getAuthenticatedUser();
    return S3.headFile(aws.CompanyBucket, path);
  }

  public getAdminFile(path: string) {
    this.getAuthenticatedUser();
    return S3.getFile(aws.AdminBucket, path);
  }
}

export const parseCognitoAttributes = (
  attributes: CognitoUserAttribute[]
): Record<string, string> => {
  const result: Record<string, string> = {};
  attributes.forEach((att) => {
    result[att.getName().replace('custom:', '')] = att.getValue();
  });
  return result;
};

export const buildCognitoAttributes = (
  attributes: { name: string; value?: string }[]
): CognitoUserAttribute[] => {
  return (
    attributes.filter((att) => att?.value !== undefined) as {
      name: string;
      value: string;
    }[]
  ).map(
    (att) =>
      new CognitoUserAttribute({
        Name: att.name,
        Value: att.value,
      })
  );
};

export const cognitoPasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/;

export const Aws = new AwsClass();
