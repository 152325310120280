import React, { ComponentType, useCallback, useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  Route as DOMRoute,
  RouteProps as DOMRouteProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '../providers/auth';

interface RouteProps extends DOMRouteProps {
  isPrivate?: boolean;
  component: ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const params = new URLSearchParams(useLocation().search);
  const language = params.get('language');

  const { user, forceChangePassword, shouldRedirect } = useAuth();

  const firstRoute = useMemo(
    () => (user?.role === 'verifier' ? '/verification' : '/home'),
    [user?.role]
  );

  useEffect(() => {
    if (language !== null) {
      i18n.changeLanguage(language);
    } else if (user?.locale !== i18n.language) {
      i18n.changeLanguage(user?.locale);
    }
  }, [i18n, language, user?.locale]);

  const render = useCallback(() => {
    const inCompletePassword = location.pathname.includes('complete-password');
    const inRegister = location.pathname.includes('register');
    const isMissingCompanySize =
      !_.isEmpty(user?.company) && _.isEmpty(user?.company.size);

    let redirect: { pathname: string; search?: string } | null = null;

    if (forceChangePassword && !inCompletePassword) {
      redirect = { pathname: '/complete-password' };
    } else if (
      user !== null &&
      _.isEmpty(user.company) &&
      !shouldRedirect &&
      !inRegister
    ) {
      redirect = {
        pathname: '/register',
        search: '?section=company-data',
      };
    } else if (
      user !== null &&
      isMissingCompanySize &&
      user.role !== 'verifier' &&
      !inRegister &&
      !shouldRedirect
    ) {
      redirect = {
        pathname: '/register',
        search: '?section=company-size&recommendedPlan=free',
      };
    } else if (user !== null && !shouldRedirect && !inRegister) {
      redirect = {
        pathname: '/register',
        search: '?section=company-size',
      };
    } else if (isPrivate !== (user !== null && shouldRedirect)) {
      redirect = {
        pathname: user === null ? '/login' : firstRoute,
      };
    } else if (user !== null && location.pathname === '/') {
      redirect = { pathname: firstRoute };
    }

    return redirect ? <Redirect to={redirect} /> : <Component />;
  }, [
    Component,
    firstRoute,
    forceChangePassword,
    isPrivate,
    location.pathname,
    shouldRedirect,
    user,
  ]);

  return <DOMRoute {...rest} render={render} />;
};

export default Route;
