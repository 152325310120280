import _ from 'lodash';

const apiURL: Record<typeof process.env.NODE_ENV, string> = {
  development: 'http://localhost:8080/api',
  test: 'http://localhost:8080/api',
  production: _.get(process.env, 'REACT_APP_API_URL', ''),
};

export const sentry = {
  dsn: _.get(process.env, 'REACT_APP_SENTRY_DSN', ''),
};

export const api = {
  baseURL: apiURL[process.env.NODE_ENV],
};

export const websiteUrl = _.get(process.env, 'REACT_APP_WEBSITE_URL', '');

export const stack = _.get(
  process.env,
  'REACT_APP_STACKNAME',
  'sustaintracker-dev'
);

export const aws = {
  UserPoolId: _.get(process.env, 'REACT_APP_AWS_COGNITO_USER_POOL_ID', ''),
  ClientId: _.get(process.env, 'REACT_APP_AWS_COGNITO_CLIENT_ID', ''),
  IdentityPoolId: _.get(
    process.env,
    'REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID',
    ''
  ),
  Region: _.get(process.env, 'REACT_APP_AWS_REGION', 'eu-west-1'),

  CognitoUserBucket: `${stack}-cognito-user-files`,
  CompanyBucket: `${stack}-company-files`,
  AdminBucket: `${stack}-admin-files`,
};

export const stripe = {
  pKey: _.get(process.env, 'REACT_APP_STRIPE_KEY', ''),
};
