import Api from '../api';

import {
  AuditTrail,
  AuditTrailParams,
  CloneDatasetParams,
  CompanySize,
  CompanyType,
  CreateDatasetParams,
  Dataset,
  DatasetResponse,
  DeleteDatasetResponse,
  DisclosureTopic,
  FilesInDatasetsResponse,
  Industry,
  ListDatasetParams,
  ListFilesInDatasetsParams,
  Sector,
  SustainabilityDimension,
} from './types';

class DatasetService extends Api {
  async Create(data: CreateDatasetParams) {
    return this.doRequest<DatasetResponse>({
      endpoint: '/datasets',
      data: {
        metadata: data,
        questionnaireIds: [],
        answers: [],
        verifications: [],
        approvals: [],
      },
      method: 'POST',
    });
  }

  async Clone(data: CloneDatasetParams) {
    return this.doRequest<DatasetResponse>({
      endpoint: '/datasets',
      data,
      method: 'POST',
    });
  }

  async Update(id: string, data: Dataset) {
    return this.doRequest<DatasetResponse>({
      endpoint: `/datasets/${id}`,
      data,
      method: 'PUT',
    });
  }

  async UpdateQuestionnaires(id: string) {
    return this.doRequest<DatasetResponse>({
      endpoint: `/datasets/${id}/update-questionnaires`,
      method: 'PUT',
    });
  }

  async Archive(id: string) {
    return this.doRequest<DatasetResponse>({
      endpoint: `/datasets/${id}/archive`,
      method: 'PUT',
    });
  }

  async Unarchive(id: string) {
    return this.doRequest<DatasetResponse>({
      endpoint: `/datasets/${id}/unarchive`,
      method: 'PUT',
    });
  }

  async List(data?: ListDatasetParams) {
    return this.doRequest<Dataset[]>({
      endpoint: '/datasets',
      data,
      method: 'GET',
    });
  }

  async Get(id: string) {
    return this.doRequest<Dataset>({
      endpoint: `/datasets/${id}`,
    });
  }

  async DeleteDataset(id: string) {
    return this.doRequest<DeleteDatasetResponse>({
      endpoint: `/datasets/${id}`,
      method: 'DELETE',
    });
  }

  async ListAuditTrail(id: string, data: AuditTrailParams) {
    return this.doRequest<AuditTrail[]>({
      endpoint: `/datasets/${id}/audit`,
      data,
      method: 'POST',
    });
  }

  async ListIndustries(lang: string) {
    return this.doRequest<Industry[]>({
      endpoint: `/industries/${lang}`,
    });
  }

  async ListSectors(lang: string) {
    return this.doRequest<Sector[]>({
      endpoint: `/sectors/${lang}`,
    });
  }

  async ListSustainabilityDimensions(lang: string) {
    return this.doRequest<SustainabilityDimension[]>({
      endpoint: `/sustainability-dimensions/${lang}`,
    });
  }

  async ListDisclosureTopics(lang: string) {
    return this.doRequest<DisclosureTopic[]>({
      endpoint: `/disclosure-topics/${lang}`,
    });
  }

  async ListCompanyTypes(lang: string) {
    return this.doRequest<CompanyType[]>({
      endpoint: `/company-types/${lang}`,
    });
  }

  async ListCompanySizes(lang: string) {
    return this.doRequest<CompanySize[]>({
      endpoint: `/company-sizes/${lang}`,
    });
  }

  async ListFilesInDatasets(data: ListFilesInDatasetsParams) {
    return this.doRequest<FilesInDatasetsResponse>({
      endpoint: '/datasets/files',
      data,
      method: 'GET',
    });
  }

  async DeleteFile(key: string) {
    return this.doRequest({
      endpoint: `/datasets/files/${key}`,
      method: 'DELETE',
    });
  }

  async GetFilePresignedUrl(datasetId: string, key: string) {
    return this.doRequest<string>({
      endpoint: `/datasets/${datasetId}/files/${key}`,
    });
  }

  async Export(id: string) {
    return this.doRequest<ArrayBuffer>({
      endpoint: `/datasets/${id}/export`,
      config: {
        responseType: 'arraybuffer',
      },
    });
  }

  async NotifyApprovers(id: string) {
    return this.doRequest({
      endpoint: `/datasets/${id}/notify`,
      method: 'PUT',
    });
  }
}

export const datasetService = new DatasetService();
