type Timer = (
  callback: () => void,
  delay: number
) => {
  pause: () => void;
  resume: () => void;
  stop: () => void;
};

export const timer: Timer = (callback, delay) => {
  let timerId: number | null;
  let start: number;
  let remaining = delay;

  const pause = () => {
    if (!timerId) return;
    window.clearTimeout(timerId);
    timerId = null;
    remaining -= Date.now() - start;
  };

  const resume = () => {
    if (timerId) return;
    start = Date.now();
    timerId = window.setTimeout(callback, remaining);
  };

  const stop = () => {
    if (timerId) {
      window.clearTimeout(timerId);
      timerId = null;
    }
    callback();
  };

  resume();

  return { pause, resume, stop };
};
