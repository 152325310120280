import Api from '../api';

import { Locale } from './types';

class LocaleService extends Api {
  async ListAvailableLocales() {
    return this.doRequest<Locale[]>({
      endpoint: '/locales',
    });
  }

  async ExportLocales(language: string) {
    return this.doRequest<string>({
      endpoint: `/locales/export/${language}`,
    });
  }
}

export const localeService = new LocaleService();
