import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Index } from 'flexsearch';
import { useTranslation } from 'react-i18next';

import { glossaryService } from '../services';
import { deepAssign } from '../utils/object';

interface GlossaryContextData {
  glossary?: Record<string, unknown>;
  index?: Index;
  indexTable: string[];
  subscribe: () => void;
  unsubscribe: () => void;
}

interface State {
  indices: Record<string, Index>;
  currentIndex: Index;
  data: Record<string, unknown>;
}

let indexTable: string[] = [];

const populateIndex = (
  prefix: string,
  current: Record<string, unknown>,
  index: Index
) => {
  Object.keys(current).forEach((key) => {
    const idx = `${prefix}${key}`;
    indexTable.push(idx);
    index.addAsync(indexTable.length - 1, key);

    if (typeof current[key] === 'string') {
      indexTable.push(`${idx}{.}child`);
      index.addAsync(indexTable.length - 1, current[key] as string);
    } else {
      populateIndex(
        `${idx}{.}`,
        current[key] as Record<string, unknown>,
        index
      );
    }
  });
};

const GlossaryContext = createContext<GlossaryContextData>(
  {} as GlossaryContextData
);

export const GlossaryProvider: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation('resources');

  const [state, setState] = useState<State>();
  const [shouldFetch, setShouldFetch] = useState(false);

  const subscribe = useCallback(async () => {
    setShouldFetch(true);
  }, []);

  const unsubscribe = useCallback(async () => {
    setShouldFetch(false);
  }, []);

  const fetchGlossary = useCallback(
    async (lang: string) => {
      const definitions = await glossaryService.ListDefinitions(lang);
      const acronyms = await glossaryService.ListAcronyms(lang);

      if (definitions && acronyms && !definitions?.error && !acronyms?.error) {
        const Definitions = {};
        const Acronyms = {};

        definitions.forEach((def) =>
          deepAssign(Definitions, [def.code, def.title], def.definition)
        );

        acronyms.forEach((acr) =>
          deepAssign(Acronyms, [acr.code, acr.acronym], acr.definition)
        );

        const definitionsTranslation = t('definitions.definitions');
        const acronymsTranslation = t('definitions.acronyms');

        const rawData = {};
        deepAssign(rawData, [definitionsTranslation], Definitions);
        deepAssign(rawData, [acronymsTranslation], Acronyms);

        setState((state) => {
          const indices = state?.indices || {};
          let index = indices?.[lang];
          if (!index) {
            index = new Index({ tokenize: 'forward' });
            deepAssign(indices, ['indices', lang], index);
          }

          indexTable = [];
          populateIndex('', rawData, index);
          return {
            indices,
            currentIndex: index,
            data: rawData,
          };
        });
      }
    },
    [t]
  );

  useEffect(() => {
    if (shouldFetch) fetchGlossary(i18n.language);
  }, [shouldFetch, i18n.language, fetchGlossary]);

  return (
    <GlossaryContext.Provider
      value={{
        glossary: state?.data,
        index: state?.currentIndex,
        indexTable,
        subscribe,
        unsubscribe,
      }}
    >
      {children}
    </GlossaryContext.Provider>
  );
};

export function useGlossary(): GlossaryContextData {
  const context = useContext(GlossaryContext);
  if (!context) {
    throw new Error('useGlossary must be used within a GlossaryProvider');
  }
  return context;
}
