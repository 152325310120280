import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as Sentry from '@sentry/react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { companyService } from '../services';
import { IResponse } from '../services/api';
import { Company, UpdateCompanyParams } from '../services/companies/types';
import {
  Aws,
  buildCognitoAttributes,
  parseCognitoAttributes,
} from '../utils/aws';
import { withRetries } from '../utils/retry';

import { useToast } from './toast';
import {
  ChangePasswordParams,
  ConfirmPasswordParams,
  Greeting,
  Role,
  SignInParams,
  SignUpParams,
  UpdateAttributesParams,
  User,
} from './types';

interface AuthContextData {
  user: User | null;
  forceChangePassword: boolean;

  signIn: (params: SignInParams) => Promise<unknown>;
  refreshSession: () => Promise<User>;

  // If false, the user will not be redirected to the Main routes after
  // signing in. This is used while the user goes through the registration
  // steps.
  shouldRedirect: boolean;

  // Sets the `redirect` state, redirecting the user to the Main routes.
  //
  // This is called after the user goes through the registration process.
  redirect: (val?: boolean) => void;

  completeNewPassword: (newPassword: string) => Promise<unknown>;
  signUp: (params: SignUpParams) => Promise<unknown>;
  confirmSignUp: (code: string) => Promise<unknown>;
  resendConfirmationCode: () => Promise<unknown>;
  signOut: () => Promise<void>;
  forgotPassword: (email: string) => Promise<unknown>;
  confirmPassword: (params: ConfirmPasswordParams) => Promise<unknown>;
  sendAttributeVerificationCode: () => Promise<unknown>;
  verifyEmail: (code: string) => Promise<unknown>;
  updateAttributes: (params: UpdateAttributesParams) => Promise<unknown>;
  updateCompany: (params: Omit<UpdateCompanyParams, 'id'>) => Promise<unknown>;
  setProfilepic: (profilepic: File | null) => Promise<unknown>;
  changePassword: (params: ChangePasswordParams) => Promise<unknown>;
  deleteAccount: () => Promise<unknown>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const usernameStorageId = '@ST:username';
const userStorageId = '@ST:user';
const redirectStorageId = '@ST:redirect';

export const AuthProvider: React.FC = ({ children }) => {
  const { t } = useTranslation('cognito');
  const { onErrorToast } = useToast();

  const [user, setUser] = useState<User | null>(() => {
    const user = localStorage.getItem(userStorageId);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  });

  const [shouldRedirect, setRedirect] = useState<boolean>(() => {
    const value = localStorage.getItem(redirectStorageId);
    return value === 'true';
  });

  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [forceChangePassword, setForceChangePassword] =
    useState<boolean>(false);

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser(user);
    });
  }, [user]);

  const setUserAttributes = useCallback(
    async (cognitoUser: CognitoUser) => {
      return new Promise<User>((resolve, reject) => {
        cognitoUser.getUserAttributes(async (error, result) => {
          if (error) reject(Error(t(error.message)));

          if (result) {
            const attributes = parseCognitoAttributes(result);

            let company: IResponse<Company> | null = await companyService.Get(
              attributes.company_id
            );
            if (company.error) {
              company = null;
            }

            const userData: User = {
              id: attributes.sub,
              name: attributes.name,
              email: attributes.email,
              familyName: attributes.family_name,
              greeting: attributes.greeting as Greeting,
              jobTitle: attributes.job_title,
              locale: attributes.locale,
              firstAccess: attributes.first_access === 'true',
              emailVerified: attributes.email_verified === 'true',
              role: attributes.role as Role,
              company: company ?? ({} as Company),
              siteAdmin: attributes?.site_admin === 'true',
            };

            setUser(userData);
            localStorage.setItem(userStorageId, JSON.stringify(userData));
            resolve(userData);
          }
        });
      });
    },
    [t]
  );

  useEffect(() => {
    if (
      (user !== null && _.isEmpty(user.company)) ||
      (_.isEmpty(user?.company.size) && user?.role !== 'verifier')
    ) {
      setRedirect(false);
    }
  }, [user]);

  useEffect(() => {
    async function fetchAvatar() {
      if (user === null) {
        return;
      }

      const response = await Aws.getUserFile(
        `${user.company.id}/${user.id}/profilepic`
      );

      if (response === null) {
        setUser({ ...user, avatar: null });
        return;
      }

      const avatar = new File(
        [Buffer.from((response.Body as Uint8Array).buffer)],
        'avatar'
      );

      setUser({ ...user, avatar });
    }

    if (user !== null && user.avatar === undefined) {
      withRetries(fetchAvatar).catch(console.error);
    }
  }, [user, setUser]);

  const refreshSession = useCallback(async () => {
    return new Promise<User>((resolve, reject) => {
      Aws.refreshUserSession()
        .then(async (user) => {
          if (user?.forceChangePassword) {
            setCognitoUser(user);
            setForceChangePassword(true);
          } else {
            // Save the attributes and set the `redirect` flag to `true`.
            const attributes = await setUserAttributes(user);
            resolve(attributes);
          }
        })
        .catch(reject);
    });
  }, [setUserAttributes]);

  const signIn = useCallback(
    async (params: SignInParams) => {
      const { email, password, authenticateWithoutRedirect } = params;

      return new Promise((resolve, reject) => {
        Aws.signIn(email, password)
          .then(async (user) => {
            if (authenticateWithoutRedirect) {
              // Save the user attributes to local storage, but don't set the
              // `redirect` flag.
              resolve(setUserAttributes(user));
            } else if (user?.forceChangePassword) {
              setCognitoUser(user);
              setForceChangePassword(true);
            } else {
              // Save the attributes and set the `redirect` flag to `true`.
              const attributes = setUserAttributes(user);
              localStorage.setItem(redirectStorageId, 'true');
              setRedirect(true);
              resolve(attributes);
            }
          })
          .catch(reject);
      });
    },
    [setUserAttributes]
  );

  const completeNewPassword = useCallback(
    (newPassword: string) => {
      return new Promise((resolve, reject) => {
        Aws.completeNewPassword(newPassword, cognitoUser)
          .then((user) => {
            // Save the attributes and set the `redirect` flag to `true`.
            const attributes = setUserAttributes(user);
            localStorage.setItem(redirectStorageId, 'true');
            setRedirect(true);
            setForceChangePassword(false);
            resolve(attributes);
          })
          .catch(reject);
      });
    },
    [setUserAttributes, cognitoUser]
  );

  const signUp = useCallback(async (params: SignUpParams) => {
    const { email, password, name, familyName, greeting, jobTitle, locale } =
      params;

    const attributes = buildCognitoAttributes([
      { name: 'email', value: email },
      { name: 'name', value: name },
      { name: 'family_name', value: familyName },
      { name: 'locale', value: locale ?? 'en' },
      { name: 'custom:greeting', value: greeting || '' },
      { name: 'custom:job_title', value: jobTitle },
      { name: 'custom:first_access', value: 'true' },
      { name: 'custom:role', value: 'admin' },
    ]);

    return new Promise((resolve, reject) => {
      Aws.signUp(email, password, attributes)
        .then((cognitoUser: CognitoUser) => {
          localStorage.setItem(usernameStorageId, cognitoUser.getUsername());
          resolve(cognitoUser);
        })
        .catch(reject);
    });
  }, []);

  const confirmSignUp = useCallback(async (code: string) => {
    const username = localStorage.getItem(usernameStorageId) || '';
    return Aws.confirmSignUp(username, code);
  }, []);

  const resendConfirmationCode = useCallback(async () => {
    const username = localStorage.getItem(usernameStorageId) || '';
    return Aws.resendConfirmationCode(username);
  }, []);

  const signOut = useCallback(async () => {
    try {
      await Aws.signOut();
    } catch (error) {
      console.error('error signing out:', error);
    }
    setUser(null);
    setRedirect(false);
    localStorage.removeItem(userStorageId);
    localStorage.removeItem(redirectStorageId);
  }, []);

  const localStorageListener = useCallback(
    async (e) => {
      if (e.key !== userStorageId) {
        return;
      }

      // Try and retrieve the ID token from AWS, logout if it fails.
      let token;
      try {
        token = await Aws.getIdToken();
      } catch (error) {
        console.error(error);
      }

      if (!token) {
        console.info('failed to retrieve id token, logging out');
        onErrorToast(new Error('The session has expired'));
        await signOut();
        window.location.reload();
      }

      const storedUser = localStorage.getItem(userStorageId);
      if (!storedUser && user) {
        await signOut();
      } else if (storedUser && !user) {
        setUser(JSON.parse(storedUser));
        setRedirect(true);
      }
    },
    [signOut, user, onErrorToast]
  );

  useEffect(() => {
    window.addEventListener('storage', localStorageListener);
    window.addEventListener('logout', signOut);
    return () => {
      window.removeEventListener('storage', localStorageListener);
      window.removeEventListener('logout', signOut);
    };
  }, [localStorageListener, signOut]);

  const redirect = useCallback((val = true) => {
    localStorage.setItem(redirectStorageId, val ? 'true' : 'false');
    setRedirect(true);
  }, []);

  const forgotPassword = useCallback(async (email: string) => {
    return Aws.forgotPassword(email);
  }, []);

  const confirmPassword = useCallback(async (params: ConfirmPasswordParams) => {
    const { email, code, password } = params;
    return Aws.confirmPassword(email, code, password);
  }, []);

  const sendAttributeVerificationCode = useCallback(() => {
    return Aws.sendEmailVerificationCode();
  }, []);

  const verifyEmail = useCallback(
    (code: string) => {
      return new Promise((resolve, reject) => {
        Aws.verifyEmail(code)
          .then((user) => resolve(setUserAttributes(user)))
          .catch(reject);
      });
    },
    [setUserAttributes]
  );

  const updateAttributes = useCallback(
    async (params: UpdateAttributesParams) => {
      const attributes = buildCognitoAttributes([
        { name: 'email', value: params.email },
        { name: 'name', value: params.name },
        { name: 'family_name', value: params.familyName },
        { name: 'locale', value: params.locale },
        { name: 'custom:greeting', value: params.greeting },
        { name: 'custom:job_title', value: params.jobTitle },
        { name: 'custom:first_access', value: params.firstAccess },
      ]);

      return new Promise((resolve, reject) => {
        Aws.updateAttributes(attributes)
          .then((user) => resolve(setUserAttributes(user)))
          .catch(reject);
      });
    },
    [setUserAttributes]
  );

  const updateCompany = useCallback(
    (params: Omit<UpdateCompanyParams, 'id'>) => {
      return new Promise((resolve, reject) => {
        if (user === null) reject(Error('User is null'));
        else {
          const { id } = user.company;
          companyService.Update(id, { ...params, id }).then((result) => {
            if (result?.error) reject(result.error);
            else {
              const userData = { ...user, company: result };
              setUser(userData);
              localStorage.setItem(
                userStorageId,
                JSON.stringify(_.omit(userData, 'avatar'))
              );
              resolve(userData);
            }
          });
        }
      });
    },
    [user]
  );

  const setProfilepic = useCallback(
    (profilepic: File | null) => {
      const path = `${user?.company.id}/${user?.id}/profilepic`;
      return profilepic === null
        ? Aws.deleteUserFile(path)
        : Aws.uploadUserFile(profilepic, path);
    },
    [user]
  );

  const changePassword = useCallback((params: ChangePasswordParams) => {
    const { oldPassword, newPassword } = params;
    return Aws.changePassword(oldPassword, newPassword);
  }, []);

  const deleteAccount = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      Aws.deleteAccount()
        .then(() => {
          setUser(null);
          localStorage.removeItem(userStorageId);
          resolve();
        })
        .catch((error) => reject(error));
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        forceChangePassword,
        signIn,
        shouldRedirect,
        completeNewPassword,
        signUp,
        confirmSignUp,
        resendConfirmationCode,
        signOut,
        redirect,
        forgotPassword,
        confirmPassword,
        sendAttributeVerificationCode,
        verifyEmail,
        updateAttributes,
        updateCompany,
        setProfilepic,
        changePassword,
        deleteAccount,
        refreshSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}
