export { formatCompanyValue, roundTo, parseCompanyValue } from 'common-utils';

interface Point {
  x: number;
  y: number;
}

export function polarToCartesianCoords(
  center: Point,
  radius: number,
  angleDeg: number
): Point {
  const angleRad = ((angleDeg - 90) * Math.PI) / 180.0;

  return {
    x: center.x + radius * Math.cos(angleRad),
    y: center.y + radius * Math.sin(angleRad),
  };
}

// Adds zeros to the decimal part to ensure the right number of decimal places.
export function padDecimalPlaces(value: string, decimalPlaces: number): string {
  if (decimalPlaces === 0) {
    return value;
  }

  const [integer, decimal] = value.split('.');

  const padded = (decimal ?? '').padEnd(decimalPlaces, '0');

  return `${integer}.${padded}`;
}

export function sum(...v: number[]): number {
  return v.reduce((p, c) => p + c, 0);
}

export function average(...v: number[]): number {
  return sum(...v) / v.length;
}
