import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as Icons from '../../assets/icons/toast';
import { ToastProps, ToastType } from '../../providers/types';
import { timer } from '../../utils/timer';

import {
  Container,
  Content,
  IconContainer,
  Message,
  RemoveToastButton,
  RemoveToastButtonIcon,
  TimeoutBar,
} from './sToast';

const icons: Record<ToastType, React.ReactElement> = {
  info: <Icons.Info />,
  success: <Icons.Success />,
  warning: <Icons.Warning />,
  error: <Icons.Error />,
};

export const timeout = 6000;
// export const timeout = 10000000;
export const animationTime = 300;
// export const animationTime = 3000;

const Toast: React.FC<ToastProps> = ({
  id,
  index,
  type,
  message,
  removeToast,
}) => {
  const [exit, setExit] = useState(false);

  const timeoutTimer = useMemo(() => {
    return timer(() => {
      setExit(true);
    }, timeout + animationTime);
  }, []);

  useEffect(() => {
    let timer: number;
    if (exit) {
      timer = window.setTimeout(() => {
        removeToast(id);
      }, animationTime);
    }

    return () => {
      if (timer !== undefined) window.clearTimeout(timer);
    };
  }, [exit, id, removeToast]);

  const handleMouseEnter = useCallback(() => {
    timeoutTimer.pause();
  }, [timeoutTimer]);

  const handleMouseLeave = useCallback(() => {
    timeoutTimer.resume();
  }, [timeoutTimer]);

  return (
    <Container
      id={id}
      type={type}
      index={index}
      timeout={timeout}
      animationTime={animationTime}
      $exit={exit}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <RemoveToastButton onClick={() => timeoutTimer.stop()}>
        <RemoveToastButtonIcon />
      </RemoveToastButton>
      <Content>
        <IconContainer>{icons[type]}</IconContainer>
        <Message>{message}</Message>
      </Content>
      <TimeoutBar />
    </Container>
  );
};

export default Toast;
