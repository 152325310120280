import Api from '../api';

import {
  CreateQuestionnaireParams,
  GetLatestVersionResult,
  ImportParams,
  ListAvailableParams,
  ListQuestionnaireParams,
  Questionnaire,
  QuestionnaireMetadata,
  UpdateQuestionnaireParams,
} from './types';

class QuestionnaireService extends Api {
  async List(data: ListQuestionnaireParams) {
    return this.doRequest<QuestionnaireMetadata[]>({
      endpoint: 'questionnaires',
      data,
      method: 'GET',
    });
  }

  async ListAvailable(data: ListAvailableParams) {
    return this.doRequest<QuestionnaireMetadata[]>({
      endpoint: 'questionnaires/available',
      data,
      method: 'GET',
    });
  }

  async GetLatestVersion(id: string, language: string) {
    return this.doRequest<GetLatestVersionResult>({
      endpoint: `questionnaires/${id}/latest`,
      data: { language },
      method: 'GET',
    });
  }

  async Get(id: string) {
    return this.doRequest<Questionnaire>({
      endpoint: `/questionnaires/${id}`,
    });
  }

  async Create(data: CreateQuestionnaireParams) {
    const { icon, name, type, format, plan, companies } = data;
    const formData = new FormData();
    formData.append('icon', icon);
    formData.append('name', name);
    formData.append('type', type);
    formData.append('format', format);
    formData.append('plan', plan);
    formData.append('companies', JSON.stringify(companies));

    return this.doRequest<QuestionnaireMetadata>({
      endpoint: '/questionnaires',
      data: formData,
      method: 'POST',
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async Update(id: string, data: UpdateQuestionnaireParams) {
    const { icon, name, type, format, companies } = data;
    const formData = new FormData();
    if (icon) formData.append('icon', icon);
    formData.append('name', name);
    formData.append('type', type);
    formData.append('format', format);
    formData.append('companies', JSON.stringify(companies));

    return this.doRequest<QuestionnaireMetadata>({
      endpoint: `/questionnaires/${id}`,
      data: formData,
      method: 'PUT',
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async Import(params: ImportParams) {
    const { questionnaireId, language, file } = params;
    const formData = new FormData();
    formData.append('questionnaireId', questionnaireId);
    formData.append('language', language);
    formData.append('file', file);

    return this.doRequest<QuestionnaireMetadata>({
      endpoint: '/questionnaires/import',
      data: formData,
      method: 'POST',
      config: {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    });
  }

  async Archive(id: string) {
    return this.doRequest<QuestionnaireMetadata>({
      endpoint: `/questionnaires/${id}/archive`,
      method: 'PUT',
    });
  }

  async Unarchive(id: string) {
    return this.doRequest<QuestionnaireMetadata>({
      endpoint: `/questionnaires/${id}/unarchive`,
      method: 'PUT',
    });
  }
}

export const questionnaireService = new QuestionnaireService();
